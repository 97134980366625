import Axios from "axios";
import Auth from "./auth";
import socket from "./socket";
import router from "./router";
import moment from "moment";
import { gatewayURL } from "./config";

class Api {
  constructor() {
    this.sender = Axios.create({
      baseURL: gatewayURL,
      transformRequest: [
        function(data, headers) {
          headers["Authorization"] = `Bearer ${Auth.getToken()}`;
          if (headers["Content-Type"] == "application/json") {
            return JSON.stringify(data);
          }
          return data;
        },
      ],
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.sender.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.response && error.response.data && error.response.data.code == "M00401") {
          router.push("/email-not-verified");
        } else if (error.response && error.response.data && error.response.data.code == "PLN400") {
          router.push("/account");
        } else if (error.response && error.response.data && error.response.data.code == "PLN404") {
          router.push("/account");
        } else if (error.response && error.response.status === 401) {
          const refreshToken = Auth.getRefreshToken();
          if (!refreshToken) {
            Auth.logout();
            router.push("/");
          }

          return Axios.post(`${gatewayURL}/refresh-token`, {
            refreshToken,
          })
            .then((response) => {
              Auth.setToken(response.data.token);
              Auth.setRefreshToken(response.data.refreshToken);

              error.config.headers = {
                Authorization: `Bearer ${response.data.token}`,
              };

              Axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;

              return Axios(error.config);
            })
            .catch((refreshError) => {
              console.log("refreshError", refreshError);
              if (refreshError && refreshError.response && refreshError.response.status === 401) {
                Auth.logout();
                router.push("/");
              }

              return Promise.reject(error);
            });
        }

        return Promise.reject(error);
      }
    );
  }

  async userInfo() {
    let { data } = await this.sender.get("/user/info");
    Auth.setUser(data);
    return data;
  }

  async updateUser(Name, Password, NewPassword, ReTypeNewPassword, ChangePassword) {
    let { data } = await this.sender.post("/user/update", {
      Name,
      Password,
      NewPassword,
      ReTypeNewPassword,
      ChangePassword,
    });
    return data;
  }

  async signin({ Email, Password, Name }) {
    let { data } = await this.sender.post("/signin", {
      Email,
      Password,
      Name,
    });

    Auth.setToken(data.token);
    Auth.setRefreshToken(data.refreshToken);
    socket.connect();

    return data;
  }

  async login({ Email, Password, LoginType }) {
    let { data } = await this.sender.post(`/login/${LoginType}`, {
      Email,
      Password,
    });

    Auth.setToken(data.token);
    Auth.setRefreshToken(data.refreshToken);
    socket.connect();

    return data;
  }

  async adlogin({ Domain, User, Password, LoginType }) {
    let { data } = await this.sender.post(`/login/${LoginType}`, {
      Domain,
      User,
      Password,
    });

    Auth.setToken(data.token);
    Auth.setRefreshToken(data.refreshToken);
    socket.connect();

    return data;
  }

  async verifyEmail(Code) {
    let { data } = await this.sender.get("/verify-email/" + Code);
    Auth.clearToken();
    return data;
  }

  async forgotPassword(email) {
    let { data } = await this.sender.get("/forget-password/" + email);
    return data;
  }

  async renewPassword(token, Password) {
    let { data } = await this.sender.post("/renew-password/" + token, {
      Password,
    });
    return data;
  }

  async createAgent() {
    let { data } = await this.sender.post("/agent");
    return data;
  }

  async registerAgent({ Id, Name, HostKey, Username, Password }) {
    let { data } = await this.sender.post("/agent/register", {
      Id,
      Name,
      HostKey,
      Username,
      Password,
    });
    return data;
  }

  async updateAgent({ Name, Id, Username, Password }) {
    let { data } = await this.sender.patch(`/agent/${Id}`, {
      Name,
      Username,
      Password,
    });
    return data;
  }

  async deleteAgent(AgentId) {
    let { data } = await this.sender.delete("/agent/" + AgentId);
    return data;
  }

  async disconnectAgent(AgentId) {
    let { data } = await this.sender.post(`/agent/${AgentId}/disconnect`);
    return data;
  }

  async agentTasks(agentId) {
    let { data } = await this.sender.get("/agent/" + agentId + "/tasks");
    return data;
  }

  async listAgents() {
    let { data } = await this.sender.get("/agent");
    return data;
  }

  async filterAgents({ itemsPerPage, page, Name, Id, Username, Status }) {
    let query = "?";

    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}&`);
    Name && (query += `name=${Name}&`);
    Id && (query += `id=${Id}&`);
    Username && (query += `username=${Username}&`);
    Status > 0 && (query += `status=${Status}&`);

    let { data } = await this.sender.get(`/agent/filter${query}`);
    return data;
  }

  async listAgentDownloadSettigns() {
    let { data } = await this.sender.get("/agent/download");
    return data;
  }

  /**
   *
   * @param {FormData} formData
   */
  async createScript(formData) {
    let { data } = await this.sender({
      method: "POST",
      url: "/script",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  }

  /**
   *
   * @param {FormData} formData
   */
  async updateScript(formData) {
    let { data } = await this.sender({
      method: "PATCH",
      url: `/script/${formData.get("ScriptId")}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  }

  async listScripts() {
    let { data } = await this.sender.get("/script");
    return data;
  }

  async listScriptsWithPage({ itemsPerPage, page }) {
    let query = "?";
    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}&`);

    let { data } = await this.sender.get(`/script/page${query}`);
    return data;
  }

  async deleteScript(scriptId) {
    let { data } = await this.sender.delete("/script/" + scriptId);
    return data;
  }

  async listTasks() {
    let { data } = await this.sender.get("/task");
    return data;
  }

  async listTasksWithPage({ itemsPerPage, page }) {
    let query = "?";
    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}&`);

    let { data } = await this.sender.get(`/task/page${query}`);
    return data;
  }

  async listTasksForFilter() {
    let { data } = await this.sender.get("/task/filter");
    return data;
  }

  async createTask(task) {
    let { data } = await this.sender.post("/task", task);
    return data;
  }

  async updateTask({ Name, Id, agents, ScriptId, CronDefinition, ScreenshotOnError, RetryOnFail, MaxRetries, UserPermission, assets }) {
    let { data } = await this.sender.patch(`/task/${Id}`, {
      Name,
      agents,
      ScriptId,
      CronDefinition,
      ScreenshotOnError,
      RetryOnFail,
      MaxRetries,
      UserPermission,
      assets,
    });
    return data;
  }

  async runTask(form) {
    let { data } = await this.sender.post(`/task/${form.get("TaskId")}/run`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }

  async deleteTask(TaskId) {
    let { data } = await this.sender.delete(`/task/${TaskId}`);
    return data;
  }

  async stopTask(TaskId) {
    let { data } = await this.sender.post(`/task/${TaskId}/stop`);
    return data;
  }

  async toggleTask(TaskId, force = false) {
    let { data } = await this.sender.post(`/task/${TaskId}/toggle?force=${force}`);
    return data;
  }

  async listAlerts() {
    let { data } = await this.sender.get("/alert");
    return data;
  }

  async listFilterAlerts({ itemsPerPage, page }) {
    let query = "?";
    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}`);

    let { data } = await this.sender.get(`/alert/query/filter${query}`);
    return data;
  }

  /** @param {AlertData} alertData */
  async createAlert(alertData) {
    // console.log(arguments[0]);

    let { data } = await this.sender.post("/alert", alertData);

    return data;
  }

  async deleteAlert(id) {
    let { data } = await this.sender.delete("/alert/" + id);
    return data;
  }

  async getAlert(id) {
    let { data } = await this.sender.get("/alert/" + id);
    return data;
  }

  /** @param {AlertData} alertData */
  async updateAlert(id, alertData) {
    let { data } = await this.sender.patch("/alert/" + id, alertData);
    return data;
  }

  async sendTestMail(mails) {
    let { data } = await this.sender.post("/alert/test-mail/" + mails);
    return data;
  }

  async sendTestWebHookMessage(url) {
    let { data } = await this.sender.post("/alert/test-webhook/", {
      url,
    });
    return data;
  }

  async listJobs() {
    let { data } = await this.sender.get("/job");
    return data;
  }

  async listJobLogs(jobId){
    let { data } = await this.sender.post("/joblog", {
      JobId: jobId
    });
    return data;
  }

  async getCategoriesWithPlugins() {
    let { data } = await this.sender.get("/plugin");
    return data;
  }

  async filterJobs(options = {}) {
    let { page, itemsPerPage, taskIds, agentIds, scriptIds, statuses, sortBy, sortDesc, startDateTime, endDateTime } = options;

    let sort;

    if (sortBy && sortDesc) {
      sort = sortBy.map((item, index) => [item, sortDesc[index] ? "DESC" : "ASC"]);
    }

    let query = "?";
    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}&`);
    taskIds && (query += taskIds.map((id) => `taskIds[]=${id}`).join("&") + "&");
    scriptIds && (query += scriptIds.map((id) => `scriptIds[]=${id}`).join("&") + "&");
    agentIds && (query += agentIds.map((id) => `agentIds[]=${id}`).join("&") + "&");
    statuses && (query += statuses.map((s) => `statuses[]=${s}`).join("&") + "&");
    startDateTime &&
      (query += `startDateTime=${moment(startDateTime)
        .utc()
        .toString()}&`);
    endDateTime &&
      (query += `endDateTime=${moment(endDateTime)
        .utc()
        .toString()}&`);
    sort && (query += `sort=` + JSON.stringify(sort));

    let { data } = await this.sender.get("/job/filter" + query);
    return data;
  }

  async downloadFile(url) {
    await this.sender(url, {
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "file.zip"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  }

  async getPlanScriptSize() {
    let { data } = await this.sender.get("/script/size");
    return data;
  }

  async getUserPlan() {
    let { data } = await this.sender.get("/user/plan");
    return data;
  }

  async getUserPlanStatus() {
    let { data } = await this.sender.get("/user/plan/status");
    return data;
  }

  async getAccountInfo() {
    let { data } = await this.sender.get("/account");
    return data;
  }

  async createCustomer(body) {
    let { data } = await this.sender.post("/payment/create-customer", body);
    return data;
  }

  async createSubscription(body) {
    let { data } = await this.sender.post("/payment/subscription", body);
    return data;
  }

  async checkout(body) {
    let { data } = await this.sender.post("/payment/checkout", body);
    return data;
  }

  async sendEnterpriseRequest(PlanName, PaymentCycle) {
    let { data } = await this.sender.post("/account/enterprise-request", {
      PlanName,
      PaymentCycle,
    });
    return data;
  }

  async updatePaymentMethod() {
    let { data } = await this.sender.patch("/payment/method");
    return data;
  }

  async hasPaymentCustomer() {
    let { data } = await this.sender.get("/user/customer");
    return data;
  }

  async getSubUsers() {
    let { data } = await this.sender.get("/user/sub");
    return data;
  }

  async filterSubUsers({ itemsPerPage, page, name, email, role, department, title }) {
    let query = "?";
    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}&`);
    name && (query += `name=${name}&`);
    email && (query += `email=${email}&`);
    role && (query += `role=${role}&`);
    department && (query += `department=${department}&`);
    title && (query += `title=${title}&`);

    let { data } = await this.sender.get(`/user/sub/filter${query}`);
    return data;
  }

  async createUser(user) {
    let { data } = await this.sender.post("/user/create", user);
    return data;
  }

  async getUserRoles() {
    let { data } = await this.sender.get("/user/role");
    return data;
  }

  async toggleUserStatus(userId) {
    let { data } = await this.sender.put(`/user/toggle/${userId}`);
    return data;
  }

  async updateSubUser(userId, user) {
    let { data } = await this.sender.put(`/user/sub/${userId}`, user);
    return data;
  }

  async getSubUserById(userId) {
    let { data } = await this.sender.get(`/user/sub/${userId}`);
    return data;
  }

  async getUserAuthorization(userId) {
    let { data } = await this.sender.get(`/user/sub/${userId}/authorization`);
    return data;
  }

  async toggleViewAuthorization(userId, pageId) {
    let { data } = await this.sender.put(`/user/sub/${userId}/authorization/view`, {
      pageId,
    });
    return data;
  }

  async toggleActionAuthorization(userId, attributeId) {
    let { data } = await this.sender.put(`/user/sub/${userId}/authorization/action`, {
      attributeId,
    });
    return data;
  }

  async toggleDataAuthorization(userId, dataId, attributeId) {
    let { data } = await this.sender.put(`/user/sub/${userId}/authorization/data`, {
      dataId,
      attributeId,
    });
    return data;
  }

  async getPages() {
    let { data } = await this.sender.get(`/user/page`);
    return data;
  }

  async getApiInfo() {
    let { data } = await this.sender.get(`/user/api`);
    return data;
  }

  async scriptTasks(scriptId) {
    let { data } = await this.sender.get(`/script/${scriptId}/tasks`);
    return data;
  }

  async getQueues() {
    let { data } = await this.sender.get(`/queue`);
    return data;
  }

  async filterQueues({ itemsPerPage, page }) {
    let query = "?";
    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}&`);

    let { data } = await this.sender.get(`/queue/filter${query}`);
    return data;
  }

  async createQueue(queue) {
    let { data } = await this.sender.post("/queue", queue);
    return data;
  }

  async updateQueue(queueId, queue) {
    let { data } = await this.sender.patch(`/queue/${queueId}`, queue);
    return data;
  }

  async deleteQueue(queueId) {
    let { data } = await this.sender.delete(`/queue/${queueId}`);
    return data;
  }

  async getQueueItems(queueId) {
    let { data } = await this.sender.get(`/queue/${queueId}/items`);
    return data;
  }

  async exportQueueItems(queueId) {
    let { data } = await this.sender.get(`/queue/${queueId}/items/export`);
    return data;
  }

  async addQueueItem(queueId, queueItem) {
    let { data } = await this.sender.post(`/queue/${queueId}/item`, queueItem);
    return data;
  }

  async deleteQueueItem(queueItemId) {
    let { data } = await this.sender.delete(`/queue/item/${queueItemId}`);
    return data;
  }

  async getAssets() {
    let { data } = await this.sender.get(`/asset`);
    return data;
  }

  async filterAssets({ itemsPerPage, page }) {
    let query = "?";
    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}&`);

    let { data } = await this.sender.get(`/asset/filter${query}`);
    return data;
  }

  async createAsset(asset) {
    let { data } = await this.sender.post("/asset", asset);
    return data;
  }

  async updateAsset(assetId, asset) {
    let { data } = await this.sender.patch(`/asset/${assetId}`, asset);
    return data;
  }

  async deleteAsset(assetId) {
    let { data } = await this.sender.delete(`/asset/${assetId}`);
    return data;
  }

  async getAssetTypes() {
    let { data } = await this.sender.get(`/asset/type`);
    return data;
  }

  async getLabels() {
    let { data } = await this.sender.get(`/asset/label`);
    return data;
  }

  async createLabel(label) {
    let { data } = await this.sender.post(`/asset/label`, label);
    return data;
  }

  async updateLabel(labelId, label) {
    let { data } = await this.sender.patch(`/asset/label/${labelId}`, label);
    return data;
  }

  async deleteLabel(labelId) {
    let { data } = await this.sender.delete(`/asset/label/${labelId}`);
    return data;
  }

  async getRadars() {
    let { data } = await this.sender.get(`/radar`);
    return data;
  }

  async filterRadars({ itemsPerPage, page, status, hostKey, hostName, username }) {
    let query = "?";
    page && (query += `page=${page - 1}&`);
    itemsPerPage && (query += `itemsPerPage=${itemsPerPage}&`);
    status && (query += `status=${status}&`);
    hostKey && (query += `hostKey=${hostKey}&`);
    hostName && (query += `hostName=${hostName}&`);
    username && (query += `username=${username}&`);

    let { data } = await this.sender.get(`/radar/filter${query}`);
    return data;
  }

  async updateRadar(item) {
    let { data } = await this.sender.patch(`/radar/${item.id}`, {
      status: item.status,
    });
    return data;
  }

  async getRadarStatuses() {
    let { data } = await this.sender.get(`/radar/status`);
    return data;
  }

  async updateSmtp(smtp) {
    let { data } = await this.sender.put("/smtp", smtp);
    return data;
  }

  async getSmtp() {
    let { data } = await this.sender.get("/smtp");
    return data;
  }

  async getTaskStatus() {
    let { data } = await this.sender.get("/job/status");
    return data;
  }

  async getDigistaffs() {
    let { data } = await this.sender.get("/digistaff");
    return data;
  }

  async createDigistaff(digistaff) {
    let { data } = await this.sender.post("/digistaff", digistaff);
    return data;
  }

  async updateDigistaff(digistaff) {
    let { data } = await this.sender.patch(`/digistaff/${digistaff.Id}`, {
      Name: digistaff.Name,
      Departments: digistaff.DepartmentIds,
    });
    return data;
  }

  async deleteDigistaff(digistaffId) {
    let { data } = await this.sender.delete(`/digistaff/${digistaffId}`);
    return data;
  }

  async getDepartments() {
    let { data } = await this.sender.get(`/digistaff/department`);
    return data;
  }

  async createDepartment(department) {
    let { data } = await this.sender.post(`/digistaff/department`, department);
    return data;
  }

  async deleteDepartment(departmentId) {
    let { data } = await this.sender.delete(`/digistaff/department/${departmentId}`);
    return data;
  }

  async getLoginType() {
    let { data } = await this.sender.get(`/digistaff/logintype`);
    return data;
  }

  async getLoginTypeById(id) {
    let { data } = await this.sender.get(`/digistaff/logintype/${id}`);
    return data;
  }

  async getSkills(digistaffId) {
    let { data } = await this.sender.get(`/digistaff/${digistaffId}/skill`);
    return data;
  }

  async createSkill(digistaffId, skill) {
    let { data } = await this.sender.post(`/digistaff/${digistaffId}/skill`, skill);
    return data;
  }

  async updateSkill(digistaffId, id, skill) {
    let { data } = await this.sender.patch(`/digistaff/${digistaffId}/skill/${id}`, {
      Name: skill.Name,
      Code: skill.Code,
    });
    return data;
  }

  async deleteSkill(digistaffId, id) {
    let { data } = await this.sender.delete(`/digistaff/${digistaffId}/skill/${id}`);
    return data;
  }

  async getDepartmentAuths(skillId) {
    let { data } = await this.sender.get(`/digistaff/department/auth/skill/${skillId}`);
    return data;
  }

  async createDepartmentAuth(auth) {
    let { data } = await this.sender.post(`/digistaff/department/auth`, auth);
    return data;
  }

  async deleteDepartmentAuth(authId) {
    let { data } = await this.sender.delete(`/digistaff/department/auth/${authId}`);
    return data;
  }

  async getTitleAuths(skillId) {
    let { data } = await this.sender.get(`/digistaff/title/auth/skill/${skillId}`);
    return data;
  }

  async createTitleAuth(auth) {
    let { data } = await this.sender.post(`/digistaff/title/auth`, auth);
    return data;
  }

  async deleteTitleAuth(authId) {
    let { data } = await this.sender.delete(`/digistaff/title/auth/${authId}`);
    return data;
  }

  async getUserAuths(skillId) {
    let { data } = await this.sender.get(`/digistaff/user/auth/skill/${skillId}`);
    return data;
  }

  async createUserAuth(auth) {
    let { data } = await this.sender.post(`/digistaff/user/auth`, auth);
    return data;
  }

  async toggleUserAuth(authId, auth) {
    let { data } = await this.sender.patch(`/digistaff/user/auth/${authId}`, auth);
    return data;
  }

  async deleteUserAuth(authId) {
    let { data } = await this.sender.delete(`/digistaff/user/auth/${authId}`);
    return data;
  }

  async getTitles() {
    let { data } = await this.sender.get(`/digistaff/title`);
    return data;
  }

  async createTitle(title) {
    let { data } = await this.sender.post(`/digistaff/title`, title);
    return data;
  }

  async deleteTitle(titleId) {
    let { data } = await this.sender.delete(`/digistaff/title/${titleId}`);
    return data;
  }

  async saveLicenseKey(Key) {
    let { data } = await this.sender.post(`/user/license`, { Key });
    return data;
  }

  async getTimeZones() {
    let { data } = await this.sender.get(`/time-zone/all`);
    return data;
  }

  async getTimeZone() {
    let { data } = await this.sender.get(`/time-zone`);
    return data;
  }

  async saveTimeZone(timeZone) {
    let { data } = await this.sender.put(`/time-zone`, { timeZone });
    return data;
  }

  async updateAd(ad) {
    let { data } = await this.sender.put("/ad", ad);
    return data;
  }

  async getAd() {
    let { data } = await this.sender.get("/ad");
    return data;
  }

  async getSessionInfo() {
    let { data } = await this.sender.get("/user/session/info");
    return data;
  }
}

/**
 * @typedef AlertData
 * @property {string} Id
 * @property {string} Name
 * @property {number} NotificationType
 * @property {number} NotifyOn
 * @property {string} EmailList - List of emails seperated with ";"
 * @property {string} SlackWebHookUrl
 * @property {string} MicrosoftTeamsWebHookUrl
 * @property {string} GoogleChatWebHookUrl
 */

export default new Api();
