// src/plugins/vuetify.js

import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    themes: {
      light: {
        primary: "#663f5c",
        secondary: "#7ccc63",
        accent: "#8c9eff",
        error: "#b71c1c",
        red: "#e74c3d",
        textcolor: "#757575",
      },
    },
  },
});
