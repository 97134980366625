<template>
  <div>
    <v-container>
      <v-row>
        <v-col v-for="item in smallWidget" :key="item.swname">
          <v-card>
            <v-list-item router :to="item.swlink" link>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  {{ item.swname }}
                </div>
                <template v-if="item.swcontent.length == 1">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ item.swcontent[0].swcount }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.swcontent[0].swdesc }}
                  </v-list-item-subtitle>
                </template>
                <template v-else>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ item.swcontent[0].swcount }} - {{ item.swcontent[1].swcount }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.swcontent[0].swdesc }} - {{ item.swcontent[1].swdesc }}
                  </v-list-item-subtitle>
                </template>
              </v-list-item-content>
              <v-list-item-avatar rounded size="80">
                <v-icon size="70" color="#653f5c">{{ item.swicon }}</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" class="gcharts-d">
          <v-card v-for="item in runTimeWidget" :key="item.name">
            <v-row>
              <v-col cols="12" sm="8" md="8" lg="10">
                <h5 class="py-4 px-4">{{ item.name }}</h5>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="2">
                <v-select 
                class="mr-5 ml-5" 
                v-if="item.action !== undefined" 
                :items="item.action.data"
                :item-text="item => item.Name" 
                :item-value="item => item.Id" 
                :label="item.action.name" 
                dense outlined flat
                @change="onChange">
                </v-select>
              </v-col>
            </v-row>
            <v-subheader v-if="item.action.data === null">No data available</v-subheader>
            <GChart v-else type="AreaChart" :data="chartData4" :options="chartOptions4" />
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="gcharts-d">
          <v-card>
            <h5 class="py-4 px-4">AGENTS</h5>
            <v-subheader v-show="agents.length == 0">No data available</v-subheader>
            <GChart v-show="agents.length != 0" type="PieChart" :data="chartData" :options="chartOptions" />
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="gcharts-d">
          <v-card>
            <h5 class="py-4 px-4">JOBS</h5>
            <v-subheader v-if="chartOptions2.colors == 0">No data available</v-subheader>
            <GChart v-else type="PieChart" :data="chartData2" :options="chartOptions2" />
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="gcharts-d">
          <v-card>
            <h5 class="py-4 px-4">EXECUTIONS</h5>
            <v-subheader v-if="chartOptions3.colors == 0">No data available</v-subheader>
            <GChart v-else type="PieChart" :data="chartData3" :options="chartOptions3" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HypeApi from "../hype/api";
import RunApi from "../api";
import { mapState, mapActions } from "vuex";
import { FETCH_AGENTS } from "../store/modules/agent";
import { FETCH_TASK_WITH_PAGE } from "../store/modules/task";
import { FETCH_SCRIPTS } from "../store/modules/script";

export default {
  async created() {
    this.fetchAgents();
    this.fetchScripts();
    this.fetchTasks({ options: {}, isRefreshing: false });
    this.fetchWorkflows();
    let taskStatus = await RunApi.getTaskStatus();
    this.jobStaskStatus = taskStatus.map((job) => {
      return {
        Status: this.getStatusText(job.Status),
        StatusCount: job.StatusCount,
      };
    });
    let executions = await HypeApi.getExecutions({
      limit: 0,
      lastId: null,
      filter: undefined,
      page: 0,
    });
    this.executions = executions["results"].map((execution) => {
      return {
        WorkflowName: execution.workflowName,
        Finished: execution.finished
      }
    });
    let executionStatus = await HypeApi.getTotalExecutionsCount();
    executionStatus = executionStatus.map((execution) => {
      return {
        Status: this.capitalizeFirstLetter(execution.Status),
        StatusCount: execution.StatusCount,
      };
    });
    this.jobStaskStatus = this.jobStaskStatus.reduce((filtered, job) => {
      let execution = executionStatus.find((item) => { return item.Status === job.Status; });
      filtered.push({
        Status: job.Status,
        StatusCount: execution ? execution.StatusCount + job.StatusCount : job.StatusCount,
      });
      return filtered;
    }, []);
    this.totalJobs = this.jobStaskStatus.reduce((total, item) => (total += item.StatusCount), 0);
    this.chartOptions2.colors = this.getColor();
    let listJobs = await RunApi.listJobs();
    this.jobs = listJobs.filter((p) => p.FinishedAt !== null).map((job) => {
      return {
        AgentId: job.AgentId,
        StartedAt: job.StartedAt,
        FinishedAt: job.FinishedAt,
      };
    });
  },
  computed: {
    ...mapState("agent", { agents: (state) => state.agents, }),
    ...mapState("script", { scripts: (state) => state.scripts, }),
    ...mapState("task", { taskCount: (state) => state.totalCount, }),
    ...mapState("workflows", { workflows: (state) => state.workflows, }),
    ...mapState("account", { planStatus: (state) => state.planStatus, }),
    runTimeWidget() {
      return [
        {
          name: "RUNTIME",
          totalRunTime: this.totalRunTime,
          action: {
            type: "Combobox",
            name: "Agents",
            data: this.agents
          },
        }
      ];
    },
    smallWidget() {
      return [
        {
          swname: "AGENTS",
          swicon: "laptop_mac",
          swcontent: [
            {
              swdesc: "Total",
              swcount: this.agents.length
            }
          ],
          swlink: "/agents"
        },
        {
          swname: "SCRIPTS",
          swicon: "queue_play_next",
          swcontent: [
            {
              swdesc: "Total",
              swcount: this.scripts.length
            }
          ],
          swlink: "/scripts",
        },
        {
          swname: "TASKS",
          swicon: "playlist_add_check",
          swcontent: [
            {
              swdesc: "Total",
              swcount: this.taskCount
            }
          ],
          swlink: "/tasks",
        },
        {
          swname: "WORKFLOWS",
          swicon: "timeline",
          swcontent: [
            {
              swdesc: "Total",
              swcount: this.workflows.length
            }
          ],
          swlink: "/workflows",
        },
        {
          swname: "TRANSACTIONS",
          swicon: "playlist_play",
          swcontent: [
            {
              swdesc: "Used",
              swcount: this.totalJobs
            },
            {
              swdesc: "Remaining",
              swcount: this.planStatus.remainingJob
            }
          ],
          swlink: "/jobs",
        }
      ];
    },
    chartData() {
      let connectedNumber = this.agents.filter(
        (agent) => agent.Status == "Connected"
      ).length;
      return [
        ["Status", "Per"],
        ["Connected", connectedNumber],
        ["Disconnected", this.agents.length - connectedNumber],
      ];
    },
    chartData2() {
      let data = this.jobStaskStatus.map((job) => {
        return [job.Status, job.StatusCount];
      });
      data.unshift(["Status", "Per"]);
      return data;
    },
    chartData3() {
      let successCount = this.executions.filter((execution) => execution.Finished == true).length;
      return [
        ["Status", "Per"],
        ["Success", successCount],
        ["Fail", this.executions.length - successCount],
      ];
    },
    chartData4() {
      if (this.totalRunTime == undefined) {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let data = [];
        let total = 0;
        let jobStartTimes = this.jobs.filter((q) => new Date(q.StartedAt).getFullYear() === new Date().getFullYear()).map((p) => p.StartedAt);
        let jobFinishTimes = this.jobs.filter((q) => new Date(q.StartedAt).getFullYear() === new Date().getFullYear()).map((p) => p.FinishedAt);
        monthNames.forEach((monthName, i) => {
          jobStartTimes.forEach((jobStartTime, j) => {
            if (monthNames[new Date(jobStartTime).getMonth()] == monthName) {
              total += ((new Date(jobFinishTimes[j]).getTime() - new Date(jobStartTimes[j]).getTime()) / (1000 * 60 * 60));
            }
          });
          data[i] = [monthName, total];
          total = 0;
        });
        data.unshift(['Months', 'Runtime(h)']);
        return data;
      }
      return this.totalRunTime;
    }
  },

  methods: {
    ...mapActions("agent", { fetchAgents: FETCH_AGENTS, }),
    ...mapActions("task", { fetchTasks: FETCH_TASK_WITH_PAGE, }),
    ...mapActions("script", { fetchScripts: FETCH_SCRIPTS, }),
    ...mapActions("workflows", { fetchWorkflows: "loadWorkflows", }),
    getColor() {
      return this.jobStaskStatus.map((job) => {
        switch (job.Status) {
          case "Success":
            return "#4CAF50";
          case "Failure":
            return "#D50000";
          case "Running":
            return "#FFEB3B";
          case "Queue":
            return "#673AB7";
          case "Sending":
            return "#607D8B";
          case "Disconnected":
            return "#FF9800";
          case "Declined":
            return "#2196F3";
        }
      });
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return "Running";
        case 2:
          return "Success";
        case 3:
          return "Failure";
        case 4:
          return "Sending";
        case 5:
          return "Disconnected";
        case 6:
          return "Queue";
        case 7:
          return "Declined";
      }
    },
    capitalizeFirstLetter(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    onChange(item) {
      console.log(new Date().getFullYear());
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let data = [];
      let total = 0;
      let jobStartTimes = this.jobs.filter((q) => q.AgentId === item && new Date(q.StartedAt).getFullYear() === new Date().getFullYear()).map((p) => p.StartedAt);
      let jobFinishTimes = this.jobs.filter((q) => q.AgentId === item && new Date(q.StartedAt).getFullYear() === new Date().getFullYear()).map((p) => p.FinishedAt);
      monthNames.forEach((monthName, i) => {
        jobStartTimes.forEach((jobStartTime, j) => {
          if (monthNames[new Date(jobStartTime).getMonth()] == monthName) {
            total += ((new Date(jobFinishTimes[j]).getTime() - new Date(jobStartTimes[j]).getTime()) / (1000 * 60 * 60));
          }
        });
        data[i] = [monthName, total];
        total = 0;
      });
      data.unshift(['Months', 'Runtime(h)']);
      this.totalRunTime = data;
    }
  },
  data() {
    return {
      chartOptions: {
        colors: ["#4CAF50", "#D50000"],
        height: 300,
        pieHole: 0.4,
      },
      chartOptions2: {
        colors: [],
        height: 300,
        pieHole: 0.4,
      },
      chartOptions3: {
        colors: ["#4CAF50", "#D50000"],
        height: 300,
        pieHole: 0.4,
      },
      chartOptions4: {
        height: 300,
        title: 'Hours',
        subtitle: 'Mounts',
      },
      jobs: [],
      jobStaskStatus: [],
      executions: [],
      totalJobs: 0,
      totalRunTime: undefined
    };
  },
};
</script>