/* eslint-disable prettier/prettier */
let typeFunctionBinding = {
  options: options,
  string: string,
  boolean: boolean,
  json: string,
  number: string,
  color: color,
  collection: collection,
  multiOptions: multiOptions,
  fixedCollection: fixedCollection,
};

let typeDataTypeBinding = {
  options: "string",
  multiOptions: "array",
  string: "string",
  boolean: "boolean",
  json: "string",
  number: "number",
  color: "string",
  collection: "object",
  fixedCollection: "array",
};

const defaults = {
  hasExpressionEditor: true,
  disabled: false,
  path: "",
  isWebhookNode: false,
};

import uuid from "short-uuid";

export function convert(_properties, _options) {
  let options = { ...defaults, ..._options };
  let properties = _properties.filter((p) => {
    if (typeFunctionBinding[p.type]) {
      return true;
    } else {
      console.log("unimplemented", p);
      return false;
    }
  });
  let uiSchema = properties
    .map((prop) => propertyToComponent(prop, options))
    .reduce((a, b) => {
      if (Array.isArray(b)) {
        a.push(...b);
      } else {
        a.push(b);
      }

      return a;
    }, []);

  let dynamicNodeParameters = getDynamicNodeParameters(properties);

  let schema = {
    type: "object",
    properties: {},
  };

  properties.forEach((prop) => {
    schema.properties[prop.name] = { type: typeDataTypeBinding[prop.type] };
  });

  return {
    schema,
    uiSchema,
    defaultData: getDefaultValues(_properties, _options),
    dynamicNodeParameters,
  };
}

function getDynamicNodeParameters(properties) {
  let dynamicNodeParameters = {};
  for (const property of properties) {
    if (property.typeOptions?.loadOptionsMethod) {
      dynamicNodeParameters[property.name] = property.typeOptions?.loadOptionsMethod;
    }
  }
  return dynamicNodeParameters;
}

function getDefaultValues(properties, _options) {
  let data = {};
  for (const property of properties) {
    data[property.name] = property.default;
  }
  //another line of code i dont want to write, but you know conditions :/
  if (_options.isWebhookNode) {
    data["path"] = uuid.generate();
  }

  return data;
}

function propertyToComponent(property, options) {
  const component = typeFunctionBinding[property.type](property, options);
  if (property.displayOptions) {
    component.displayOptions = convertDisplayOptions(property.displayOptions);
  }
  return component;
}

function convertDisplayOptions(nodeDisplayOptions) {
  const displayOptions = {
    schema: {
      properties: {},
    },
  };
  if (nodeDisplayOptions?.show) {
    for (const [key, values] of Object.entries(nodeDisplayOptions.show)) {
      displayOptions.schema.properties[key] = {
        enum: values,
      };
    }

    displayOptions.schema.required = Object.keys(nodeDisplayOptions.show);
  }

  return displayOptions;
}

/**
 *
 * @param {EnvironmentTypeData} data
 */
function options(property, options) {
  let component = getComponent("dynamic-select", property.name, {
    property,
    options,
    items: property.typeOptions?.loadOptionsMethod ? [] : extractOptions(property.options),
    path: getDotNotation(options.path, property.name),
  });

  return component;
}

/**
 *
 * @param {EnvironmentTypeData} data
 */
function multiOptions(property, options) {
  let component = getComponent("dynamic-select", property.name, {
    property,
    options,
    items: property.typeOptions?.loadOptionsMethod ? [] : extractOptions(property.options),
    multiple: true,
    path: getDotNotation(options.path, property.name),
  });

  if (property.description) {
    component.children = [getDefinitionTip(property.name, property.description)];
  }

  return component;
}

// function findRelativeInputValue(parents) {
//   for (const elem of parents) {
//     let input = elem.querySelector('input')
//     if (input) {
//       return input.value
//     }
//   }
// }

function string(property, options) {
  let component = getComponent("dynamic-text-field", property.name, {
    property,
    options,
    path: getDotNotation(options.path, property.name),
  });

  return component;
}

function boolean(property, options) {
  let component = getComponent("dynamic-boolean", property.name, {
    property,
    options,
    path: getDotNotation(options.path, property.name),
  });

  return component;
}

function color(property, options) {
  let component = getComponent("v-color-picker", property.name, {
    mode: "hexa",
    "dot-size": "25",
    "swatches-max-height": 100,
    disabled: options.disabled,
    path: getDotNotation(options.path, property.name),
  });

  if (property.description) {
    component.children = [getDefinitionTip(property.name, property.description)];
  }

  return component;
}

function collection(property, options) {
  let component = getComponent("dynamic-collection", property.name, {
    properties: property.options,
    name: property.name,
    disabled: options.disabled,
    path: getDotNotation(options.path, property.name),
    displayName: property.displayName,
    options,
  });

  if (property.description) {
    component.children = [getDefinitionTip(property.name, property.description)];
  }

  return component;
}

function fixedCollection(property, options) {
  console.log("fixed", getDotNotation(options.path, property.name));
  let component = getComponent("fixed-dynamic-collection", property.name, {
    properties: property.options,
    name: property.name,
    displayName: property.displayName,
    disabled: options.disabled,
    path: getDotNotation(options.path, property.name),
    options,
  });

  if (property.description) {
    component.children = [getDefinitionTip(property.name, property.description)];
  }

  return component;
}

function getComponent(name, model, props, on = {}, id = null, componentOptions = {}) {
  return {
    ...componentOptions,
    component: name,
    model: model,

    fieldOptions: {
      id,
      on: {
        input: "input",
        change: "change",
        ...on,
      },
      props,
    },
  };
}

function getDotNotation(...elems) {
  return elems.filter((e) => !!e).join(".");
}

function getDefinitionTip(name, description) {
  return {
    component: "template",
    fieldOptions: {
      slot: "append-outer",
    },
    children: [
      {
        component: "definition-tip",
        fieldOptions: {
          props: {
            name: name,
            definition: description,
          },
        },
      },
    ],
  };
}

function extractOptions(options) {
  return options.map((p) => ({ text: p.name, value: p.value }));
}
