<template>
  <v-row justify="center">
    <v-col cols="12" md="6" xs="6" class="mx-auto">
      <v-card class="shadow-xl pa-5" :loading="isUpdating">
        <v-card-text>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-divider class="my-5"></v-divider>
          <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="!!success" text prominent type="success" icon="mdi-cloud-check">Your information has been updated.</v-alert>
          <v-form>
            <v-text-field label="Full Name" v-model="userInfo.name" maxlength="30" counter type="text" outlined :rules="nameRules" />
            <v-text-field label="E-Mail" v-model="userInfo.email" disabled required outlined />

            <v-btn class="mb-5" @click="userInfo.changePassword = !userInfo.changePassword" depressed text v-if="userInfo.loginType === 'User'"
              >Change Password</v-btn
            >
            <div class="changepassword" v-show="userInfo.changePassword" v-if="userInfo.loginType === 'User'">
              <v-text-field
                maxlength="50"
                counter
                v-model="userInfo.password"
                label="Aviable Password"
                type="password"
                required
                outlined
                :rules="aviablePasswordRules"
              />
              <v-text-field
                v-model="userInfo.newPassword"
                maxlength="50"
                counter
                label="New Password"
                name="newPassword"
                type="password"
                outlined
                :rules="newPasswordRules"
              />
              <v-text-field
                v-model="userInfo.reTypeNewPassword"
                maxlength="50"
                counter
                label="Renew Password"
                type="password"
                outlined
                :rules="renewPasswordRules"
              />
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="save()" :disabled="isUpdating" depressed large block>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { UPDATE_USER, REFRESH_UPDATE_STATE } from "../../store/modules/user";

export default {
  data: () => ({
    userInfo: {
      name: "",
      email: "",
      loginType: "",
      password: "",
      newPassword: "",
      reTypeNewPassword: "",
      changePassword: false,
    },
    nameRules: [(v) => !!v || "Full name is required"],
    aviablePasswordRules: [(v) => !!v || "Aviable password is required"],
    newPasswordRules: [(v) => !!v || "New password is required"],
    renewPasswordRules: [(v) => !!v || "Renew password is required"],
  }),
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
      isUpdating: (state) => state.isUpdating,
      error: (state) => state.updateError,
      success: (state) => state.updateSuccess,
    }),
  },
  watch: {
    user(value) {
      if (value) {
        this.userInfo.loginType = value.LoginType;
        this.userInfo.name = value.Name;
        this.userInfo.email = value.Email;
      }
    },
  },
  created() {
    if (this.user) {
      this.userInfo.loginType = this.user.LoginType;
      this.userInfo.name = this.user.Name;
      this.userInfo.email = this.user.Email;
    }
  },
  methods: {
    ...mapActions("user", {
      updateUser: UPDATE_USER,
      refreshState: REFRESH_UPDATE_STATE,
    }),
    save() {
      this.updateUser(this.userInfo);
    },
  },
};
</script>
