<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        <v-icon left>add</v-icon>New User
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Create User</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-show="!!error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="!!success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Succesfully Created</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="editedItem.Name"
                  label="Name"
                  required
                  :rules="nameRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="editedItem.Email"
                  label="Email"
                  required
                  :rules="emailRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="editedItem.UserRole"
                  :items="userRoles"
                  item-text="name"
                  item-value="name"
                  dense
                  label="Select Role"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="editedItem.LoginTypeId"
                  :items="logintypes"
                  item-text="Name"
                  item-value="Id"
                  dense
                  label="Select Login Type"
                  :disabled="success"
                  @change="changeLoginType"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="editedItem.AdName"
                  label="Active Dİrectory Name"
                  required
                  maxlength="50"
                  counter
                  :disabled=isDisabled
                ></v-text-field>
              </v-col>
              <v-col cols="8" sm="4" md="8">
                <v-autocomplete
                  v-model="editedItem.DepartmentId"
                  :items="departments"
                  item-text="Name"
                  item-value="Id"
                  dense
                  label="Select Department"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" sm="2" md="4">
                <v-btn
                  color="primary"
                  depressed
                  v-on:click="toggleDepartmentDialog"
                  :disabled="success"
                  >Departments</v-btn
                >
              </v-col>
              <v-col cols="8" sm="4" md="8">
                <v-autocomplete
                  v-model="editedItem.TitleId"
                  :items="titles"
                  item-text="Name"
                  item-value="Id"
                  dense
                  label="Select Title"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" sm="2" md="4">
                <v-btn
                  color="primary"
                  depressed
                  v-on:click="toggleTitleDialog"
                  :disabled="success"
                  >Titles
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            v-show="!success && !loading"
            @click="save"
            >Save</v-btn>
          <v-btn color="grey darken-1" text @click="closeCreateDialog"
            >Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  CLOSE_CREATE_USER_DIALOG,
  OPEN_CREATE_USER_DIALOG,
  CREATE_USER,
} from "../../store/modules/user";
import {
  TOGGLE_DEPARTMENT_DIALOG,
  TOGGLE_TITLE_DIALOG,
  FETCH_DEPARTMENTS,
  FETCH_TITLES,
  FETCH_LOGIN_TYPES,
  CHANGE_LOGIN_TYPES,
} from "../../store/modules/digistaff";

export default {
  computed: {
    dialog: {
      get() {
        this.adNameToggle(this.$store.state.digistaff.isDisabled);
        return this.$store.state.user.subUser.createDialog;
      },
      set(val) {
        if (val) {
          this.openCreateDialog();
        } else {
          this.closeCreateDialog();
        }
      },
    },
    ...mapState("user", {
      loading: (state) => state.subUser.isLoading,
      error: (state) => state.subUser.createError,
      success: (state) => state.subUser.createSuccess,
      userRoles: (state) => state.subUser.userRoles,
    }),
    ...mapState("digistaff", {
      departments: (state) => state.departments,
      titles: (state) => state.titles,
      logintypes: (state) => state.logintypes,
    }),
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetchDepartments();
        this.fetchTitles();
        this.fetchLoginType();
        Object.assign(this.editedItem, {
          UserRole: "",
          Email: "",
          Name: "",
          DepartmentId: "",
          TitleId: "",
          AdName: "",
          LoginTypeId: "",
        });
      }
    },
  },
  methods: {
    ...mapActions("user", {
      openCreateDialog: OPEN_CREATE_USER_DIALOG,
      closeCreateDialog: CLOSE_CREATE_USER_DIALOG,
      createUser: CREATE_USER,
    }),
    ...mapActions("digistaff", {
      toggleDepartmentDialog: TOGGLE_DEPARTMENT_DIALOG,
      fetchDepartments: FETCH_DEPARTMENTS,
      toggleTitleDialog: TOGGLE_TITLE_DIALOG,
      fetchTitles: FETCH_TITLES,
      fetchLoginType: FETCH_LOGIN_TYPES,
      chaLoginType: CHANGE_LOGIN_TYPES,
    }),
    changeLoginType(){
      this.chaLoginType(this.editedItem.LoginTypeId);
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.createUser(this.editedItem);
      this.isDisabled = true;
    },
    adNameToggle(data){
      this.editedItem.AdName = "";
      this.isDisabled = data;
    }
  },
  data: () => ({
    nameRules: [(v) => !!v || "User name is required"],
    emailRules: [(v) => !!v || "User email is required"],
    roleRules: [(v) => !!v || "User role is required"],
    departmentRules: [(v) => !!v || "Department is required"],
    titleRules: [(v) => !!v || "Title is required"],
    isDisabled: true,
    editedItem: {
      UserRole: "",
      Email: "",
      Name: "",
      DepartmentId: "",
      TitleId: "",
      AdName: "",
      LoginTypeId: "",
    },
  }),
};
</script>
