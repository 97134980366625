<template>
  <div>
    <v-dialog width="800">
      <template v-slot:activator="{ on }">
        <v-btn block v-on="on" depressed text class="text-capitalize" outlined>
          <v-icon left>get_app</v-icon>Download Agent
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Download Agents</span>
        </v-card-title>
        <v-card-text>
          <template>
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th class="text-left">Platform</th>
                    <th class="text-left">Robbot UI</th>
                    <th class="text-left">Robbot CLI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in agentDownloadSettings" :key="item.Id">
                    <td>
                      <v-img :src="getImage(item.PlatformIcon)" width="20" right></v-img>
                    </td>
                    <td>
                      <a :href="item.UIUrl" target="_blank">Download for {{item.Platform}}</a>
                    </td>
                    <td>
                      <a :href="item.CLIUrl" target="_blank">Download for {{item.Platform}}</a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { FETCH_AGENT_DOWNLOAD_SETTINGS } from "../store/modules/agent";

export default {
  computed: {
    ...mapState("agent", {
      agentDownloadSettings: state => state.agentDownloadSettings
    })
  },
  created() {
    this.fetchAgentDownloadSettings();
  },
  methods: {
    getImage(fileName) {
      return this.$assetHelper.getImage(fileName);
    },
    ...mapActions("agent", {
      fetchAgentDownloadSettings: FETCH_AGENT_DOWNLOAD_SETTINGS
    })
  }
};
</script>