<template>
  <v-container class="py-0" v-show="planStatus.remainingJob <= 0">
    <v-alert color="black" prominent type="error"  dense>
      <v-row align="center">
        <v-col>You have reached the maximum number of jobs in your package. Upgrade your package so that your transactions are not interrupted.</v-col>
        <v-col class="shrink">
          <router-link to="/account">
            <v-btn depressed color="primary">Upgrade</v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("account", {
      planStatus: state => state.planStatus
    })
  }
};
</script>