<template>
  <div>
    <v-card class="shadow-xl mx-5 my-5 pa-2">
      <v-card-title>How do i see logs details?</v-card-title>
      <v-card-subtitle>You can view the log details from kibana.</v-card-subtitle>
      <v-card-text>Kibana is an open source data visualization dashboard for Elasticsearch. It provides visualization capabilities on top of the content indexed on an Elasticsearch cluster. Users can create bar, line and scatter plots, or pie charts and maps on top of large volumes of data.</v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-img
              v-bind:src="this.$assetHelper.getImage('logs-screen.png')"
              max-width="800"
              v-bind:lazy-src="this.$assetHelper.getImage('logs-screen.png')"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-dialog v-model="dialog" max-width="600">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" class="text-capitalize" depressed v-on="on">
              View Log Details
              <v-icon right>keyboard_arrow_right</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Login Kibana</v-card-title>
            <v-img
              class="white--text align-end"
              v-bind:src="this.$assetHelper.getImage('kibana-login.gif')"
            ></v-img>
            <v-card-text class="py-5">
              <v-alert outlined type="red" prominent border="left">
                You can use Robbot
                <strong>Username</strong> and
                <strong>Password</strong> for login kibana.
              </v-alert>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <a v-bind:href="user.KibanaURL" target="_blank">
                <v-btn depressed color="primary">
                  Contine
                  <v-icon right>keyboard_arrow_right</v-icon>
                </v-btn>
              </a>
              <a href="#">
                <v-btn depressed @click="dialog = false">Cancel</v-btn>
              </a>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    dialog: ""
  }),
  computed: mapState("user", {
    user: state => state.user
  })
};
</script>

<style>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
</style>
