<template>
  <v-app>
    <router-view></router-view>
    <form-dialog />
    <alert-dialog />
    <confirm-dialog />
  </v-app>
</template>

<script>
import ConfirmDialog from "./hype/components/dialog/ConfirmDialog";
import FormDialog from "./hype/components/dialog/FormDialog";
import AlertDialog from "./hype/components/dialog/AlertDialog";

export default {
  name: "App",
  components: { FormDialog, AlertDialog, ConfirmDialog },
};
</script>
