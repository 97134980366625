<template>
  <v-main>
    <v-container>
      <v-row align="center" justify="center" >
          <v-col cols="12" sm="10">
            <v-card class="elevation-6 mt-10"  >
             <v-window>
                <v-window-item :value="1">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-card-text class="mt-12">
                        <v-row align="center" justify="center" class="my-4">
                          <v-img v-bind:src="this.$assetHelper.getImage('logo-red.png')"
                            v-bind:lazy-src="this.$assetHelper.getImage('logo-red.png')" max-width="300"></v-img>
                        </v-row>  
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8">
                            <v-form ref="form" class="pa-5">


                          
                            <v-text-field 
                              label="E-Mail"
                              v-model="email" 
                              name="email" :prepend-inner-icon=mail
                              maxlength="50" 
                              type="text" 
                              class="mt-16"
                              counter
                              autocomplete="false"
                              color="primary"
                              :rules="emailRules"
                              outlined dense required/>



                            <v-btn color="primary" @click="lostPassword()" depressed block
                                  >Reset Password
                            </v-btn>
                            <br>
                        
                            <p class="text-center">
                                    Back to
                                    <router-link to="/">Login</router-link>
                            </p>


                        </v-form>
                          </v-col>
                        </v-row>  
                      </v-card-text>
                    </v-col>
                  
                    <v-col cols="12" md="6" class="primary rounded-bl-xl" >
                    <div style="  text-align: center; padding: 180px 0;">
                      <v-img v-bind:src="this.$assetHelper.getImage('hyperautomation.png')"
                            v-bind:lazy-src="this.$assetHelper.getImage('hyperautomation.png')"></v-img>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
      </v-row>
  </v-container>
  </v-main>
</template>

<script>
import Api from "../api";
export default {
  data() {
    return {
      sent: false,
      email: "",
      emailRules: [(v) => !!v || "E-Mail is required"],
      firstNameRules: [(v) => !!v || "Name is required"],
      lastNameRules: [(v) => !!v || "Name is required"],
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  props: {
    source: String,
  },
  methods: {
    lostPassword() {
      Api.forgotPassword(this.email).then(() => (this.sent = true));
    },
  },
};
</script>

<style scoped>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
</style>
