<template>
  <div>
    <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="queues"
      class="shadow-xl mx-5 my-5"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Queues</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <new-queue-dialog v-if="hasAuth('New Queue')" />
          <edit-queue-dialog />
          <delete-queue-dialog />
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom v-if="item.DataItems">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => redirectDetail(item.Id)" v-on="on">
              <v-icon size="18">add</v-icon>
            </v-btn>
          </template>
          <span>Queue Items</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataExport">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => exportToExcel(item.Id)" v-on="on">
              <v-icon size="18">cloud_download</v-icon>
            </v-btn>
          </template>
          <span>Download Queue Items</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataEdit">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="() => editQueue(item)" v-on="on">
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Queue</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.DataDelete">
          <template v-slot:activator="{ on }">
            <v-btn depressed @click="deleteQueue(item.Id)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Queue</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FILTER_QUEUES, TOGGLE_UPDATE_QUEUE_DIALOG, TOGGLE_DELETE_QUEUE_DIALOG } from "../../store/modules/queue";
import { timeAwareFormat } from "../../time";
import NewQueueDialog from "./NewQueueDialog";
import EditQueueDialog from "./EditQueueDialog";
import DeleteQueueDialog from "./DeleteQueueDialog";
import Api from "../../api";
import { unparse } from "papaparse";
import { hasActionAuthorization } from "../../authorization";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "Name",
      },
      {
        text: "Description",
        value: "Description",
      },
      {
        text: "Created",
        value: "createdAt",
      },
      {
        text: "Created By",
        value: "Created.Name",
      },
      {
        text: "In Progress",
        value: "InProgress",
      },
      {
        text: "Remaining",
        value: "Remaining",
      },
      {
        text: "Successful",
        value: "Successful",
      },
      {
        text: "Failure",
        value: "Failure",
      },
      {
        text: "Average Time",
        value: "Average",
      },
      {
        text: "Actions",
        value: "action",
      },
    ],
    options: { itemsPerPage: 10, page: 1 },
  }),
  components: {
    "new-queue-dialog": NewQueueDialog,
    "edit-queue-dialog": EditQueueDialog,
    "delete-queue-dialog": DeleteQueueDialog,
  },
  computed: {
    ...mapState("queue", {
      queues: (state) =>
        state.pagedQueues.map((i) => ({
          ...i,
          createdAt: timeAwareFormat(i.createdAt),
          Average: `${i.Average} m`,
        })),
      totalCount: (state) => state.totalCount,
      isLoading: (state) => state.queueIsLoading,
    }),
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal.page != oldVal.page || newVal.itemsPerPage != oldVal.itemsPerPage) {
          this.fetchQueues(newVal);
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchQueues(this.options);
  },
  methods: {
    ...mapActions("queue", {
      fetchQueues: FILTER_QUEUES,
      toggleEditDialog: TOGGLE_UPDATE_QUEUE_DIALOG,
      toggleDeleteDialog: TOGGLE_DELETE_QUEUE_DIALOG,
    }),
    hasAuth(action) {
      return hasActionAuthorization(this.$store, "Queues", action);
    },
    redirectDetail(id) {
      this.$router.push(`/queue/item/${id}`);
    },
    async exportToExcel(id) {
      let queueItems = await Api.exportQueueItems(id);

      const blob = new Blob([unparse(queueItems)], {
        type: "text/csv;charset=utf-8;",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "QueueItems.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    editQueue(item) {
      this.toggleEditDialog(item);
    },
    deleteQueue(id) {
      this.toggleDeleteDialog(id);
    },
  },
};
</script>
