<template>
  <div>
    <div>
      <v-navigation-drawer app expand-on-hover permanent :mini-variant.sync="drawer" clipped>
        <v-list dense>
          <v-list-item v-for="item in items" :key="item.text" router :to="item.route" link
            v-bind:class="{ bottomline: item.bottomline }">
            <v-list-item-action>
              <v-icon style="font-weight: bolder;">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <v-app-bar height="65" app clipped-left color="primary" dense class="white--text shadow-md">
      <!-- <v-app-bar-nav-icon dark @click.stop="drawer = !drawer" />
      <v-spacer /> -->
      <router-link to="/" class="v-toolbar__title">
        <v-img v-bind:src="this.$assetHelper.getImage('robbot-logo.png')"
          v-bind:lazy-src="this.$assetHelper.getImage('robbot-logo.png')" max-width="110">
        </v-img>
      </router-link>
      <v-spacer />
      <div class="d-none d-sm-flex">
        <router-link to="/account" class="black--text" v-show="planStatus.remainingTrialDay">
          <v-chip>Trial {{ planStatus.remainingTrialDay }} days left</v-chip>
        </router-link>
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text class="white--text text-capitalize font-weight-light">
            <span class="d-none d-sm-flex mr-3">{{ user.Name }}</span>
            <v-icon left>keyboard_arrow_down</v-icon>
            <v-icon large>account_circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
              <v-icon x-large>account_circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{ user.Name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.Email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item-group v-for="(page, index) in menus" v-bind:key="index">
            <v-list-item v-bind:to="page.url">
              <v-list-item-icon>
                <v-icon size="25">settings</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ page.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-list-item v-for="item in items.filter((p) => p.text === 'User Management')" :key="item.text" router
            :to="item.route" link>
            <v-list-item-icon>
              <v-icon size="25">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" link>
            <v-list-item-icon>
              <v-icon size="25">logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
import Auth from "../auth";
import eventBusMixin from "../hype/mixins/eventBus";
import { mapState, mapActions, mapMutations } from "vuex";
import { FETCH_USER } from "../store/modules/user";

export default {
  mixins: [eventBusMixin],
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
  }),
  created() {
    this.fetchUser();
  },
  mounted() {
    this.$subscribe("navigationDrawerStatus", (status) => {
      this.drawer = status;
    });
  },
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
      items: (state) => {
        return state.pages.reduce((filtered, page) => {
          if (
            page.Url &&
            (page.Place === "OnlyMenu" || page.Place === "HeaderAndMenu")
          ) {
            filtered.push({
              icon: page.Icon,
              text: page.Name,
              route: page.Url,
              bottomline: page.Bottomline,
            });
          }
          return filtered;
        }, []);
      },
      menus: (state) => {
        return state.pages.reduce((filtered, page) => {
          if (
            page.Url &&
            (page.Place === "OnlyHeader" || page.Place === "HeaderAndMenu")
          ) {
            filtered.push({
              text: page.Name,
              url: page.Url,
            });
          }
          return filtered;
        }, []);
      },
    }),
    ...mapState("account", {
      planStatus: (state) => state.planStatus,
    }),
  },
  methods: {
    ...mapMutations("user", {
      setPages: "setPages",
    }),
    logout() {
      Auth.logout();
      this.setPages([]);
      this.$router.push("/");
    },
    ...mapActions("user", {
      fetchUser: FETCH_USER,
    }),
    trialControl() {
      return this.planStatus.remaining.TrialDay;
    },
  },
};
</script>

<style>
.bottomline {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 15px;
}
</style>
