import Vue from "vue";
import Vuex from "vuex";
import agent from "./modules/agent";
import script from "./modules/script";
import task from "./modules/task";
import user from "./modules/user";
import job from "./modules/job";
import confirm from "./modules/confirm";
import alert from "./modules/alert";
import account from "./modules/account";
import queue from "./modules/queue";
import asset from "./modules/asset";
import radar from "./modules/radar";
import NodesStore from "./modules/node";
import CredentialsStore from "./modules/credentials";
import EditorStore from "./modules/editor";
import FormDialogStore from "./modules/formDialog";
import workflows from "./modules/workflows";
import DynamicFormStore from "./modules/dynamicForm";
import smtp from "./modules/smtpInformation";
import digistaff from "./modules/digistaff";
import timeZone from "./modules/timeZone";
import activeDirectory from "./modules/activeDirectory";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    agent,
    script,
    task,
    user,
    job,
    confirm,
    alert,
    account,
    queue,
    asset,
    radar,
    node: NodesStore,
    credentials: CredentialsStore,
    editor: EditorStore,
    formDialog: FormDialogStore,
    workflows,
    dynamicForm: DynamicFormStore,
    smtp,
    digistaff,
    timeZone,
    activeDirectory,
  },
  strict: debug,
});
