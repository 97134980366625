<template>
  <div>
    <v-data-table :headers="headers" :items="jobs" :options.sync="options" :server-items-length="this.totalCount"
      :footer-props="{ itemsPerPageOptions: [10, 50, 100] }" class="shadow-xl mx-5 my-5">

      <template v-slot:top>
        <v-toolbar flat color="white" class="pt-3">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Jobs</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="px-5">
          <v-col cols="12" xs="4" sm="3" md="3">
            <v-autocomplete v-model="agentIds" :items="agents" item-text="Name" item-value="Id" multiple
              label="Select Agents"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="4" sm="3" md="3">
            <v-autocomplete v-model="scriptIds" :items="scripts" item-text="Name" item-value="Id" multiple
              label="Select Script"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="4" sm="3" md="3">
            <v-autocomplete v-model="taskIds" :items="tasks" item-text="Name" item-value="Id" multiple
              label="Select Task"></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="3" sm="3" md="3">
            <v-autocomplete v-model="statuses" :items="status" item-text="name" item-value="value" label="Select Status"
              multiple></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="px-5">
          <v-col cols="12" xs="3" sm="3" md="3">
            <v-datetime-picker label="Start Time" v-model="startDateTime"></v-datetime-picker>
          </v-col>
          <v-col cols="12" xs="3" sm="3" md="3">
            <v-datetime-picker label="End Time" v-model="endDateTime"></v-datetime-picker>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="6">
            <v-row>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-btn color="primary" block @click="filter">
                  <v-icon left dark>
                    mdi-refresh
                  </v-icon>
                  Refresh
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <vue-json-to-csv :json-data="jobs" :csv-title="'jobs'">
                  <v-btn color="primary" block>
                    <v-icon left dark>
                      mdi-download
                    </v-icon>
                    CSV
                  </v-btn>
                </vue-json-to-csv>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <download-excel :data="jobs" :name="'jobs'">
                  <v-btn color="primary" block>
                    <v-icon left dark>
                      mdi-download
                    </v-icon>
                    Excel
                  </v-btn>
                </download-excel>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.Status="{ item }">
        <v-chip :color="getColor(item.Status)" dark>{{ item.Status[0].toUpperCase() +
          item.Status.slice(1).toLowerCase() }}</v-chip>
      </template>
      <template v-slot:item.Screenshot="{ item }">
        <v-tooltip bottom v-if="item.Screenshot">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" v-bind:href="screenshotUrl(item.Screenshot, item.Id)" target="_blank" v-on="on">
              <v-icon size="18">tv</v-icon>
            </v-btn>
          </template>
          <span>Screenshot</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn depressed class="mx-1" @click="openLogTableDialog(item)">
          <v-icon>visibility</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div v-show="visible === 'true'">
      <job-log-dialog-table />
    </div>
  </div>
</template>

<script>
import Api from "../api";
import socket from "../socket";
import { mapState, mapActions } from "vuex";
import { FETCH_AGENTS } from "../store/modules/agent";
import { FETCH_SCRIPTS } from "../store/modules/script";
import { OPEN_JOB_LOG_TABLE_DIALOG } from "../store/modules/job";
import { timeAwareFormat } from "../time";
//import Auth from "../auth";
//import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv"
import JsonExcel from "vue-json-excel";
import JobLogDialog from "./JobsLogs";
import { gatewayURL } from "../config";

export default {
  computed: {
    ...mapState("agent", {
      agents: (state) => state.agents,
    }),
    ...mapState("script", {
      scripts: (state) => state.scripts,
    }),
  },
  components: {
    "job-log-dialog-table": JobLogDialog,
    "vue-json-to-csv": VueJsonToCsv,
    "downloadExcel": JsonExcel,
  },
  data: () => ({
    options: {
      sortBy: ["createdAt"],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1
    },
    visible: false,
    taskIds: [],
    agentIds: [],
    scriptIds: [],
    statuses: [],
    status: [
      {
        name: "Running",
        value: 1,
      },
      {
        name: "Success",
        value: 2,
      },
      {
        name: "Failure",
        value: 3,
      },
      {
        name: "Sending",
        value: 4,
      },
      {
        name: "Disconnected",
        value: 5,
      },
      {
        name: "Queue",
        value: 6,
      },
      {
        name: "Declined",
        value: 7,
      },
    ],
    outlined: "outlined",
    search: "",
    startDateTime: "",
    endDateTime: "",
    valid: false,
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
    loading: false,
    headers: [
      {
        text: "Agent Name",
        align: "left",
        sortable: false,
        value: "Agent.Name",
      },
      {
        text: "Script Name",
        align: "left",
        sortable: false,
        value: "Script.Name",
      },
      {
        text: "Task Name",
        align: "left",
        sortable: false,
        value: "Run.Task.Name",
      },
      {
        text: "Elapsed Time (s)",
        align: "left",
        sortable: false,
        value: "ElapsedTime",
      },
      {
        text: "Created Time",
        align: "left",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Start Time",
        align: "left",
        sortable: true,
        value: "StartedAt",
      },
      {
        text: "End Time",
        align: "left",
        sortable: true,
        value: "FinishedAt",
      },
      {
        text: "Status",
        align: "left",
        sortable: false,
        value: "Status",
      },
      {
        text: "Screenshot",
        align: "left",
        sortable: false,
        value: "Screenshot",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    jobs: [],
    tasks: [],
  }),

  created() {
    this.initialize();
    socket.on("update_job_status", () => {
      this.filter();
    });

    socket.on("update_job_disconnect", () => {
      this.filter();
    });
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        this.filter();
      },
      deep: true,
    },
  },
  methods: {
    onCopy: function () {
      // alert('You just copied: Hook ID')
    },
    onError: function () {
      alert("Failed to copy Hook ID");
    },
    ...mapActions("agent", {
      fetchAgents: FETCH_AGENTS,
    }),
    ...mapActions("script", {
      fetchScripts: FETCH_SCRIPTS,
    }),
    ...mapActions("job", {
      openLogTableDialog: OPEN_JOB_LOG_TABLE_DIALOG,
    }),
    filter() {
      Api.filterJobs({
        ...this.options,
        taskIds: this.taskIds,
        agentIds: this.agentIds,
        scriptIds: this.scriptIds,
        statuses: this.statuses,
        startDateTime: this.startDateTime,
        endDateTime: this.endDateTime,
      }).then((data) => {
        this.jobs = this.transformJobs(data.rows);
        this.totalCount = data.count;
        this.loading = false;
      });
    },
    getColor(status) {
      switch (status) {
        case "SUCCESS":
          return "green";
        case "FAILURE":
          return "red";
        case "RUNNING":
          return "yellow";
        case "QUEUE":
          return "purple";
        case "SENDING":
          return "magenta";
        case "DISCONNECTED":
          return "orange";
        case "DECLINED":
          return "blue";
      }
    },
    transformStatus(num) {
      switch (num) {
        case 1:
          return "RUNNING";

        case 2:
          return "SUCCESS";

        case 3:
          return "FAILURE";

        case 4:
          return "SENDING";

        case 5:
          return "DISCONNECTED";

        case 6:
          return "QUEUE";

        case 7:
          return "DECLINED";
      }
    },
    screenshotUrl(screenshot, jobId) {
      return screenshot ? `${gatewayURL}/job/screenshot/${jobId}` : "#";
    },
    screenshotTarget(screenshot) {
      return screenshot ? "_blank" : "";
    },
    transformJobs(jobs) {
      //let user = Auth.getUser();

      return jobs.map((job) => {
        //job.JobKibanaUrl = this.generateKibanaLink(user.KibanaURL, job.CreatedBy, job.Id, job.StartedAt, job.FinishedAt);
        if (job.StartedAt) {
          job.ElapsedTime = ((job.FinishedAt ? new Date(job.FinishedAt) : new Date()).getTime() - new Date(job.StartedAt).getTime()) / 1000;
        }
        job.createdAt = timeAwareFormat(job.createdAt);
        job.StartedAt = job.StartedAt && timeAwareFormat(job.StartedAt);
        job.FinishedAt = job.FinishedAt && timeAwareFormat(job.FinishedAt);
        job.Status = this.transformStatus(job.Status);
        return job;
      });
    },
    initialize() {
      this.fetchTasks();
      this.fetchAgents();
      this.fetchScripts();
      this.filter();
    },
    async fetchTasks() {
      this.tasks = await Api.listTasksForFilter();
    },
  },
};
</script>

<style>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.SUCCESS {
  background-color: #27ae60 !important;
}

.QUEUE {
  background-color: rgb(219, 216, 40) !important;
}

.FAILURE {
  background-color: #c0392b !important;
}
</style>
