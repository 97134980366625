import Api from "../../api";
export const FETCH_JOBS = "FETCH_JOBS";
export const OPEN_JOB_LOG_TABLE_DIALOG = "OPEN_JOB_LOG_TABLE_DIALOG";
export const CLOSE_JOB_LOG_TABLE_DIALOG = "CLOSE_JOB_LOG_TABLE_DIALOG";


const state = {
  totalCount: 0,
  jobs: [],
  pagedJobs: [],
  isLoading: false,
  error: "",
  openLogTableDialog: false,
  data: [],
  jobid: 0
};

const getters = {};

const actions = {
  [OPEN_JOB_LOG_TABLE_DIALOG]: async ({ commit }, job) => {
    //let jobs = await Api.listJobLogs(job.Id);
    //console.log(jobs);
    commit("openLogTableDialog", job.Id);

  },
  [CLOSE_JOB_LOG_TABLE_DIALOG]: ({ commit }) => {
    commit("closeLogTableDialog");
  },
  [FETCH_JOBS]: async ({ commit }) => {
    commit("setLoading", true);
    try {
      let jobs = await Api.listJobs();
      jobs = jobs.map((job) => {
        switch (job.Status) {
          case 1:
            job.Status = "RUNNING";
            break;
          case 2:
            job.Status = "SUCCESS";
            break;
          case 3:
            job.Status = "FAILURE";
            break;
          case 4:
            job.Status = "SENDING";
            break;
          case 5:
            job.Status = "DISCONNECTED";
            break;
          case 6:
            job.Status = "QUEUE";
            break;
          case 7:
            job.Status = "DECLINED";
            break;
        }
        return job;
      });
     // commit("setPagedJobs", jobs);
      commit("setJobs", jobs);
    } catch (err) {
      commit("setError", err.response.data.reason);
    }
    commit("setLoading", false);
  },
};



const mutations = {
 // setPagedJobs: (state, jobs) => (state.pagedJobs = jobs),
  openLogTableDialog: (state, jobId) => {
    state.openLogTableDialog = true;
    state.jobid = jobId;
  },
  closeLogTableDialog: (state) => {
    state.openLogTableDialog = false;
  },
  setJobs: (state, jobs) => (state.jobs = jobs),
  setLoading: (state, status) => (state.isLoading = status),
  setError: (state, error) => (state.error = error),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
