<template>
  <v-main>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-card class="elevation-6 mt-10">
            <v-window>
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <v-row align="center" justify="center" class="my-4">
                        <v-img v-bind:src="this.$assetHelper.getImage('logo-red.png')"
                          v-bind:lazy-src="this.$assetHelper.getImage('logo-red.png')" max-width="300"></v-img>
                      </v-row>
                      <v-alert v-show="!!errorMessage" text prominent type="error" icon="mdi-alert-circle">{{
                        errorMessage }}</v-alert>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <v-form ref="form" class="pa-5">
                            <v-text-field :label=prop.formlabel v-model="email" name="email"
                              :prepend-inner-icon=prop.resim maxlength="50" type="text" class="mt-16" autocomplete="false"
                              color="primary" outlined dense />

                            <v-text-field v-on:keyup.enter="login" id="password" v-model="password" label="Password"
                              maxlength="50" name="password" prepend-inner-icon="lock" type="password"
                              autocomplete="false" color="primary" outlined dense :rules="passwordRules" />

                            <v-text-field name="domain" label="Domain" prepend-inner-icon="domain" v-model="domain"
                              v-if="prop.loginType !== 'User'" type="text" maxlength="50" outlined dense required />

                            <v-row>
                              <v-col cols="12" sm="7">
                                <v-checkbox label="Remember Me" class="mt-n1" color="primary"> </v-checkbox>
                              </v-col>
                              <v-col cols="12" sm="5">
                                <router-link to="/lostpassword">Forgot password?</router-link>
                              </v-col>
                            </v-row>
                            <v-btn :color="prop.color" v-on:click="login" depressed block>
                              <span class="white--text">Login</span>
                            </v-btn>
                            <h5 class="text-center  grey--text mt-4 mb-3">yada {{ prop.buttonText }} ile giriş yapın</h5>
                            <div class="text-center">
                              <v-btn :color="prop.buttonColor" v-on:click="loginChange" depressed outlined>
                                <v-icon color="red">fas fa-{{ prop.icon }}</v-icon>
                              </v-btn>
                            </div>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" md="6" class="primary rounded-bl-xl">
                    <div style="  text-align: center; padding: 180px 0;">
                      <v-img v-bind:src="this.$assetHelper.getImage('hyperautomation.png')"
                        v-bind:lazy-src="this.$assetHelper.getImage('hyperautomation.png')"></v-img>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-progress-linear v-show="isLoading" indeterminate color="yellow darken-2"></v-progress-linear>
              <v-window-item :value="2">
                <v-row>
                  <v-col cols="12" md="6" class="primary rounded-br-xl">
                    <div style="  text-align: center; padding: 180px 0;">
                      <v-card-text class="white--text">
                        <h3 class="text-center ">Alredy Signed up?</h3>
                        <h6 class="text-center">Log in to your account so you can continue building and<br> editing your
                          onboarding flows</h6>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn tile outlined dark @click="step--">Log in</v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Api from "../api";

export default {
  data() {
    return {
      passwordRules: [(v) => !!v || "Password is required"],
      errorMessage: "",
      isLoading: false,
      email: "",
      password: "",
      prop: {
        color: "primary",
        title: "Email and Password",
        buttonText: "Active Directory",
        buttonColor: "red",
        loginType: "User",
        formlabel: "E-Mail",
        resim: "mail",
        icon: "ad"
      },
    };
  },
  methods: {
    async login() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoading = true;
      if (this.prop.loginType === "User") {
        try {
          await Api.login({
            Email: this.email,
            Password: this.password,
            LoginType: this.prop.loginType,
          });
          this.$router.push("dashboard");
        } catch (e) {
          (this.errorMessage = e.response.data.reason), (this.isLoading = false);
        }
      } else {
        try {
          await Api.adlogin({
            Domain: this.domain,
            User: this.email,
            Password: this.password,
            LoginType: this.prop.loginType,
          });
          this.$router.push("dashboard");
        } catch (e) {
          (this.errorMessage = e.response.data.reason), (this.isLoading = false);
        }
      }


    },
    loginChange() {

      if (this.prop.loginType === "User") {
        this.email = "";
        this.password = "";
        this.domain = "";
        this.prop = {
          color: "red",
          title: "Active Directory",
          buttonText: "User Login",
          buttonColor: "primary",
          loginType: "Active Directory",
          formlabel: "User Name",
          resim: "mdi-account",
          icon: "envelope"
        };
      } else {
        this.email = "";
        this.password = "";
        this.prop = {
          color: "primary",
          title: "Email and Password",
          buttonText: "Active Directory Login",
          buttonColor: "red",
          loginType: "User",
          formlabel: "E-Mail",
          resim: "mail",
          email: "",
          password: "",
          icon: "ad"
        };
      }
    },
  },
  props: {
    source: String,
  },
};
</script>

<style scoped>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
</style>
