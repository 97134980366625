import Api from "../../api";
import { diffAndAverageDate } from "../../time";
export const FETCH_QUEUES = "FETCH_QUEUES";
export const FILTER_QUEUES = "FILTER_QUEUES";
export const TOGGLE_CREATE_QUEUE_DIALOG = "TOGGLE_CREATE_QUEUE_DIALOG";
export const CREATE_QUEUE = "CREATE_QUEUE";
export const TOGGLE_UPDATE_QUEUE_DIALOG = "TOGGLE_UPDATE_QUEUE_DIALOG";
export const UPDATE_QUEUE = "UPDATE_QUEUE";
export const TOGGLE_DELETE_QUEUE_DIALOG = "TOGGLE_DELETE_QUEUE_DIALOG";
export const DELETE_QUEUE = "DELETE_QUEUE";
export const FETCH_QUEUE_ITEMS = "FETCH_QUEUE_ITEMS";
export const TOGGLE_ADD_QUEUE_ITEM_DIALOG = "TOGGLE_ADD_QUEUE_ITEM_DIALOG";
export const ADD_QUEUE_ITEM = "ADD_QUEUE_ITEM";
export const TOGGLE_VIEW_QUEUE_ITEM_DIALOG = "TOGGLE_VIEW_QUEUE_ITEM_DIALOG";
export const TOGGLE_DELETE_QUEUE_ITEM_DIALOG = "TOGGLE_DELETE_QUEUE_ITEM_DIALOG";
export const DELETE_QUEUE_ITEM = "DELETE_QUEUE_ITEM";

const state = {
  queues: [],
  pagedQueues: [],
  totalCount: 0,
  selectedQueueId: null,
  queueIsLoading: false,
  createQueue: {
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  updateQueue: {
    item: {},
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  deleteQueue: {
    id: 0,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  queueItems: [],
  queueItemIsLoading: false,
  addQueueItem: {
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  viewQueueItem: {
    dialog: false,
    item: {},
  },
  deleteQueueItem: {
    id: 0,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
};

const actions = {
  [FETCH_QUEUES]: async ({ commit }) => {
    commit("setQueueLoading", true);

    let list = [];

    try {
      list = await Api.getQueues();
      commit("setQueues", list);
    } catch (err) {
      commit("setQueues", []);
    } finally {
      commit("setQueueLoading", false);
    }

    return list;
  },
  [FILTER_QUEUES]: async ({ commit }, options) => {
    commit("setQueueLoading", true);

    try {
      let result = await Api.filterQueues(options);
      commit("setPagedQueues", result.rows);
      commit("setTotalCount", result.count);
    } catch (err) {
      commit("setPagedQueues", []);
      commit("setTotalCount", 0);
    } finally {
      commit("setQueueLoading", false);
    }
  },
  [TOGGLE_CREATE_QUEUE_DIALOG]: ({ commit }) => {
    commit("setCreateQueueDialog");
  },
  [CREATE_QUEUE]: async ({ commit, dispatch }, queue) => {
    commit("setCreateQueueLoading", true);

    try {
      await Api.createQueue(queue);
      commit("setCreateQueueSuccess");
    } catch (error) {
      commit("setCreateQueueError", error.response.data.reason);
    } finally {
      commit("setCreateQueueLoading", false);
      dispatch(FILTER_QUEUES, { itemsPerPage: 10, page: 0 });
    }
  },
  [TOGGLE_UPDATE_QUEUE_DIALOG]: ({ commit }, item = {}) => {
    commit("setUpdateQueueDialog", item);
  },
  [UPDATE_QUEUE]: async ({ commit, dispatch }, { queueId, queue }) => {
    commit("setUpdateQueueLoading", true);

    try {
      await Api.updateQueue(queueId, queue);
      commit("setUpdateQueueSuccess");
    } catch (error) {
      commit("setUpdateQueueError", error.response.data.reason);
    } finally {
      commit("setUpdateQueueLoading", false);
      dispatch(FILTER_QUEUES, { itemsPerPage: 10, page: 0 });
    }
  },
  [TOGGLE_DELETE_QUEUE_DIALOG]: ({ commit }, id = 0) => {
    commit("setDeleteQueueDialog", id);
  },
  [DELETE_QUEUE]: async ({ commit, dispatch }, queueId) => {
    commit("setDeleteQueueLoading", true);

    try {
      await Api.deleteQueue(queueId);
      commit("setDeleteQueueSuccess");
    } catch (error) {
      commit("setDeleteQueueError", error.response.data.reason);
    } finally {
      commit("setDeleteQueueLoading", false);
      dispatch(FILTER_QUEUES, { itemsPerPage: 10, page: 0 });
    }
  },
  [FETCH_QUEUE_ITEMS]: async ({ commit }, queueId) => {
    commit("setQueueItemLoading", true);

    try {
      let list = await Api.getQueueItems(queueId);
      commit("setQueueItems", list);
    } catch (err) {
      commit("setQueueItems", []);
    } finally {
      commit("setQueueItemLoading", false);
    }
  },
  [TOGGLE_ADD_QUEUE_ITEM_DIALOG]: ({ commit }) => {
    commit("setAddQueueItemDialog");
  },
  [ADD_QUEUE_ITEM]: async ({ commit, dispatch }, { queueId, queueItem }) => {
    commit("setAddQueueItemLoading", true);

    try {
      await Api.addQueueItem(queueId, queueItem);
      commit("setAddQueueItemSuccess");
    } catch (error) {
      commit("setAddQueueItemError", error.response.data.reason);
    } finally {
      commit("setAddQueueItemLoading", false);
      dispatch(FETCH_QUEUE_ITEMS, queueId);
    }
  },
  [TOGGLE_VIEW_QUEUE_ITEM_DIALOG]: ({ commit }, item = {}) => {
    commit("setViewQueueItemDialog", item);
  },
  [DELETE_QUEUE_ITEM]: async ({ commit, dispatch }, { queueId, queueItemId }) => {
    commit("setDeleteQueueItemLoading", true);

    try {
      await Api.deleteQueueItem(queueItemId);
      commit("setDeleteQueueItemSuccess");
    } catch (error) {
      commit("setDeleteQueueItemError", error.response.data.reason);
    } finally {
      commit("setDeleteQueueItemLoading", false);
      dispatch(FETCH_QUEUE_ITEMS, queueId);
    }
  },
  [TOGGLE_DELETE_QUEUE_ITEM_DIALOG]: ({ commit }, id = 0) => {
    commit("setDeleteQueueItemDialog", id);
  },
};

const mutations = {
  setQueueLoading: (state, isLoading) => (state.queueIsLoading = isLoading),
  setQueues: (state, queues) => {
    state.queues = queues.map((item) => {
      item.InProgress = item.QueueItems.filter((queueItem) => queueItem.Status === "InProgress").length;
      item.Remaining = item.QueueItems.filter((queueItem) => queueItem.Status === "New").length;
      item.Successful = item.QueueItems.filter((queueItem) => queueItem.Status === "Success").length;
      item.Failure = item.QueueItems.filter((queueItem) => queueItem.Status === "Failure").length;

      let dateArray = item.QueueItems.reduce((filtered, queueItem) => {
        if (queueItem.StartedAt && queueItem.FinishedAt) {
          filtered.push({ date1: queueItem.FinishedAt, date2: queueItem.StartedAt });
        }
        return filtered;
      }, []);
      item.Average = diffAndAverageDate(dateArray);

      return item;
    });
  },

  setPagedQueues: (state, queues) => {
    state.pagedQueues = queues.map((item) => {
      item.InProgress = item.QueueItems.filter((queueItem) => queueItem.Status === "InProgress").length;
      item.Remaining = item.QueueItems.filter((queueItem) => queueItem.Status === "New").length;
      item.Successful = item.QueueItems.filter((queueItem) => queueItem.Status === "Success").length;
      item.Failure = item.QueueItems.filter((queueItem) => queueItem.Status === "Failure").length;

      let dateArray = item.QueueItems.reduce((filtered, queueItem) => {
        if (queueItem.StartedAt && queueItem.FinishedAt) {
          filtered.push({ date1: queueItem.FinishedAt, date2: queueItem.StartedAt });
        }
        return filtered;
      }, []);
      item.Average = diffAndAverageDate(dateArray);

      return item;
    });
  },

  setTotalCount: (state, count) => (state.totalCount = count),

  setCreateQueueDialog: (state) => {
    state.createQueue.dialog = !state.createQueue.dialog;
    state.createQueue.isLoading = false;
    state.createQueue.success = false;
    state.createQueue.error = "";
  },
  setCreateQueueLoading: (state, isLoading) => (state.createQueue.isLoading = isLoading),
  setCreateQueueSuccess: (state) => (state.createQueue.success = true),
  setCreateQueueError: (state, error) => (state.createQueue.error = error),

  setUpdateQueueDialog: (state, item) => {
    state.updateQueue.item = { ...item };
    state.updateQueue.dialog = !state.updateQueue.dialog;
    state.updateQueue.isLoading = false;
    state.updateQueue.success = false;
    state.updateQueue.error = "";
  },
  setUpdateQueueLoading: (state, isLoading) => (state.updateQueue.isLoading = isLoading),
  setUpdateQueueSuccess: (state) => (state.updateQueue.success = true),
  setUpdateQueueError: (state, error) => (state.updateQueue.error = error),

  setDeleteQueueDialog: (state, id) => {
    state.deleteQueue.id = id;
    state.deleteQueue.dialog = !state.deleteQueue.dialog;
    state.deleteQueue.isLoading = false;
    state.deleteQueue.success = false;
    state.deleteQueue.error = "";
  },
  setDeleteQueueLoading: (state, isLoading) => (state.deleteQueue.isLoading = isLoading),
  setDeleteQueueSuccess: (state) => (state.deleteQueue.success = true),
  setDeleteQueueError: (state, error) => (state.deleteQueue.error = error),

  setQueueItemLoading: (state, isLoading) => (state.queueItemIsLoading = isLoading),
  setQueueItems: (state, queueItems) => (state.queueItems = queueItems),

  setAddQueueItemDialog: (state) => {
    state.addQueueItem.dialog = !state.addQueueItem.dialog;
    state.addQueueItem.isLoading = false;
    state.addQueueItem.success = false;
    state.addQueueItem.error = "";
  },
  setAddQueueItemLoading: (state, isLoading) => (state.addQueueItem.isLoading = isLoading),
  setAddQueueItemSuccess: (state) => (state.addQueueItem.success = true),
  setAddQueueItemError: (state, error) => (state.addQueueItem.error = error),

  setViewQueueItemDialog: (state, item) => {
    if (item && item.Data) {
      item.Data = JSON.stringify(JSON.parse(item.Data), undefined, 4);
    }

    state.viewQueueItem.item = item;
    state.viewQueueItem.dialog = !state.viewQueueItem.dialog;
  },

  setDeleteQueueItemDialog: (state, id) => {
    state.deleteQueueItem.id = id;
    state.deleteQueueItem.dialog = !state.deleteQueueItem.dialog;
    state.deleteQueueItem.isLoading = false;
    state.deleteQueueItem.success = false;
    state.deleteQueueItem.error = "";
  },
  setDeleteQueueItemLoading: (state, isLoading) => (state.deleteQueueItem.isLoading = isLoading),
  setDeleteQueueItemSuccess: (state) => (state.deleteQueueItem.success = true),
  setDeleteQueueItemError: (state, error) => (state.deleteQueueItem.error = error),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
