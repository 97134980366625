<template>
  <v-dialog v-model="joblogsdialog" max-width="1024px" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="blue" dark>
              Open Table
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5"> Job Logs </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :item-class= "row_classes" 
              class="shadow-xl mx-5 my-5"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100],
              }"
            >
          </v-data-table>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="joblogsdialog = false" color="red"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </template>
   <style>
  .queue {
    background-color: #9a80f8;
  }
  .finish {
    background-color: #d3ffce;
  }
  .failure {
    background-color: #e68174;
  }
  .running {
    background-color: #f1f368;
  }
  .start {
    background-color: #f8c76b;
  }
  </style> 
  <script>

  
  import Api from "../api";
  import { timeAwareFormat } from "../time";
    //import jsdata from "./test.json"
    import { OPEN_JOB_LOG_TABLE_DIALOG, CLOSE_JOB_LOG_TABLE_DIALOG } from "../store/modules/job";

    import {  mapActions } from "vuex";
export default {
  components: {
  },
  computed: {
    joblogsdialog: {
      get() {
        this.filter(this.$store.state.job.jobid);
        return this.$store.state.job.openLogTableDialog;
      },
      set(val) {
        if (val) {
          this.openLogDialog();
        } else {
          this.closeLogDialog();
        }
      },
  },
},
  async created() {
  },
  watch: {

  },
  methods: {
    row_classes(item) {
      switch (item.Status) {
        case "FINISH":
          return "finish";
        case "FAILURE":
          return "failure";
        case "RUNNING":
          return "running";
        case "QUEUE":
          return "queue";
        case "START":
          return "start";
      }
    },
    ...mapActions("job", {
      openLogDialog: OPEN_JOB_LOG_TABLE_DIALOG,
      closeLogDialog: CLOSE_JOB_LOG_TABLE_DIALOG,
    }),
    transformLogStatus(log) {
      switch (log) {
        case "Queue":
          return "QUEUE";

        case "Start":
          return "START";

        case "STDOUT":
          return "RUNNING";

        case "STDERR":
          return "FAILURE";

        case "Finish":
          return "FINISH";
      }
    },
    transformJobs(jobs) {
      return jobs.map((job) => {
        job.createdAt = timeAwareFormat(job.createdAt);
        job.updatedAt = job.StartedAt && timeAwareFormat(job.updatedAt);
        job.Status = this.transformLogStatus(job.Status);
        return job;
      });
    },
    filter(jobId) {
      if(jobId !=null && jobId !=""){
        Api.listJobLogs(jobId).then((data) => {
          if(data.count!=0){
            this.items = this.transformJobs(data);
          }else{
            this.items =[];
          }
        this.totalCount = data.count;
        this.loading = false;
      });
      }


      },
  },
  data: () => ({
    headers: [
          {
            text: "Status",
            value: "Status",
          },
          {
            text: "Job Log",
            value: "JobLog",
          },
          {
            text: "Start",
            value: "createdAt",
          }
        ],
        items:[],
        options: { itemsPerPage: 10, page: 1 }
  }),
};
</script>
    