<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-form ref="form">
        <v-card-title>
          <span class="headline">Edit Asset</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-show="!!error"
            text
            prominent
            type="error"
            icon="mdi-alert-circle"
            >{{ error }}</v-alert
          >
          <v-alert
            v-show="!!success"
            text
            prominent
            type="success"
            icon="mdi-cloud-check"
            >Succesfully Created</v-alert
          >
          <v-container class="text-center" v-if="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.Name"
                  label="Name"
                  required
                  :rules="nameRules"
                  maxlength="50"
                  counter
                  :disabled="success"
                  @keypress="nameControl($event)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.Description"
                  label="Description"
                  maxlength="200"
                  counter
                  :disabled="success"
                ></v-text-field>
              </v-col>
              <v-col cols="9" sm="4" md="9">
                <v-autocomplete
                  v-model="labelIds"
                  :items="labels"
                  item-text="Name"
                  item-value="Id"
                  dense
                  multiple
                  label="Select Labels"
                  :disabled="success"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" sm="2" md="3">
                <v-btn
                  color="primary"
                  depressed
                  v-if="hasAuth('Label Action')"
                  v-on:click="openLabelDialog"
                  :disabled="success"
                  >Labels</v-btn
                >
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="item.AssetType"
                  :items="types"
                  item-text="Name"
                  item-value="Id"
                  dense
                  label="Select Type"
                  :disabled="success"
                  v-on:change="changeType"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="item.Value"
                  label="Value"
                  maxlength="200"
                  counter
                  :disabled="success"
                  v-show="textType"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="6">
                <v-text-field
                  v-model="item.Username"
                  label="Username"
                  maxlength="100"
                  counter
                  :disabled="success"
                  v-show="credentialType"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="6">
                <v-text-field
                  v-model="item.Password"
                  label="Password"
                  maxlength="500"
                  counter
                  type="password"
                  :disabled="success"
                  v-show="credentialType"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            v-show="!success && !loading"
            @click="save"
            >Save</v-btn
          >
          <v-btn color="grey darken-1" text @click="toggleDialog">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  FETCH_LABELS,
  FETCH_TYPES,
  UPDATE_ASSET,
  TOGGLE_UPDATE_ASSET_DIALOG,
  TOGGLE_LABEL_DIALOG,
  CHANGE_DIALOG,
} from "../../store/modules/asset";
import { hasActionAuthorization } from "../../authorization";

export default {
  data: () => ({
    textType: false,
    credentialType: false,
    nameRules: [(v) => !!v || "Asset name is required"],
    typeRules: [(v) => !!v || "Type is required"],
    labelIds: [],
    assetTypeId: "",
  }),
  computed: {
    ...mapState("asset", {
      item: (state) => state.updateAsset.item,
      labels: (state) => state.labels,
      types: (state) => state.types,
      loading: (state) => state.updateAsset.isLoading,
      success: (state) => state.updateAsset.success,
      error: (state) => state.updateAsset.error,
      dialog: (state) => state.updateAsset.dialog,
    }),
  },
  watch: {
    dialog(value) {
      if (value) this.changeType();
    },
    item(value) {
      if (value) this.labelIds = value.Labels;
      else this.labelIds = [];
    },
  },
  mounted() {
    this.fetchTypes();
    this.fetchLabels();
  },
  methods: {
    ...mapActions("asset", {
      fetchLabels: FETCH_LABELS,
      fetchTypes: FETCH_TYPES,
      updateAsset: UPDATE_ASSET,
      toggleDialog: TOGGLE_UPDATE_ASSET_DIALOG,
      toggleLabelDialog: TOGGLE_LABEL_DIALOG,
      changeDialog: CHANGE_DIALOG,
    }),
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.updateAsset({
        assetId: this.item.Id,
        asset: {
          Name: this.item.Name,
          Description: this.item.Description,
          Value: this.item.Value,
          Username: this.item.Username,
          Password: this.item.Password,
          Labels: this.labelIds,
          AssetTypeId: this.item.AssetType.Id,
        },
      });
    },
    changeType() {
      if (!this.item.AssetType) {
        this.clearTextType();
        this.clearCredentialType();
      }

      if (this.item.AssetType.Name === "Text") {
        this.clearCredentialType();
        this.textType = true;
      } else if (this.item.AssetType.Name === "Credential") {
        this.clearTextType();
        this.credentialType = true;
      } else {
        this.clearTextType();
        this.clearCredentialType();
      }
    },
    clearTextType() {
      this.textType = false;
      this.item.Value = "";
    },
    clearCredentialType() {
      this.credentialType = false;
      this.item.Username = "";
      this.item.Password = "";
    },
    hasAuth(action) {
      return hasActionAuthorization(this.$store, "Assets", action);
    },
    openLabelDialog() {
      this.toggleLabelDialog();
    },
    nameControl(event) {
      let match = event.key.match(/[^A-Z]/gi);
      match ? event.preventDefault() : (event.returnValue = true);
    },
  },
};
</script>
