<template>
  <div
    style="position: relative; height: 100%; width: 100%"
    id="home"
    @keydown.ctrl.83="saveFlow"
    @keydown.meta.83="saveFlow"
    @keydown.ctrl.86="paste"
    @keydown.meta.86="paste"
  >
    <v-speed-dial
      v-if="!isExecutionResult"
      style="position: absolute; right: 20px; bottom: 80px; z-index: 2"
      open-on-hover
      v-model="fab"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-progress-circular indeterminate v-else-if="isRunning">
          </v-progress-circular>

          <v-icon v-else> mdi-dots-horizontal </v-icon>
        </v-btn>
      </template>
      <v-btn
        v-if="isRunning"
        fab
        dark
        small
        @click.stop="stopExecution"
        v-show="!disableEdit"
      >
        <v-progress-circular indeterminate>
          <v-icon>mdi-stop</v-icon>
        </v-progress-circular>
      </v-btn>
      <v-btn
        v-else
        dark
        small
        fab
        @click.stop="onRunButtonClicked"
        v-show="!disableEdit"
      >
        <v-icon>mdi-play</v-icon>
      </v-btn>
      <v-btn dark small fab @click.stop="saveFlow">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>

      <v-btn dark small fab @click.stop="exportWorkFlow">
        <v-icon>mdi-file-export</v-icon>
      </v-btn>

      <v-btn dark small fab @click.stop="pickFileField">
        <v-icon>mdi-file-import</v-icon>
        <input
          type="file"
          accept=".rbflow"
          style="display: none"
          ref="fileInput"
          @change="onFilePicked"
        />
      </v-btn>
    </v-speed-dial>

    <node-list
      :open="listShow"
      :position-x="listPositionX"
      :position-y="listPositionY"
      @clickaway="closeNodeList(true)"
      @nodeSelected="nodeSelected"
    />
    <edit-drawer
      v-model="editDrawer"
      :edited-node="editedNode"
      :disable-edit="disableEdit"
      @save="nodeDataEdited"
      @nameUpdated="updateNodeName"
    />
    <v-snackbar v-model="saveSnackBar">
      Workflow has been saved!

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-fade-transition>
      <v-chip
        large
        color="primary"
        class="white--text"
        v-show="waitingForWebhook"
        style="
          position: fixed;
          bottom: 80px;
          left: calc((100% - 250px) / 2);
          width: 250px;
          z-index: 2;
        "
      >
        <v-btn class="mr-5" icon @click="deleteWebhook">
          <v-progress-circular indeterminate>
            <v-icon>mdi-stop</v-icon>
          </v-progress-circular>
        </v-btn>
        Waiting For Webhook
      </v-chip>
    </v-fade-transition>
    <expression-data-selector />
  </div>
</template>

<script>
import Drawflow from "../js/drawflow";
// eslint-disable-next-line no-unused-vars
import styleDrawFlow from "../js/drawflow";
import Vue from "vue";
import Node from "../components/Node";
import NodeList from "../components/editor/NodeList";
import EditDrawer from "../components/editor/EditDrawer";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { convertFlowData } from "../helpers/workflowConverter";
import Api from "../api";
import srcApi from "../../api";
import EventListener from "../eventListener";
import NodeNamer from "../helpers/nodeNamer";
import ExpressionDataSelector from "../components/editor/ExpressionDataSelector";
import formDialog from "../mixins/formDialog";
import { waitInMilliSeconds } from "../helpers/wait";
import eventBusMixin from "../mixins/eventBus";
import { deepCopy } from "../helpers/object";
import { convert } from "../helpers/propertyConverter";
import { readFileAsText } from "../helpers/file";
import { saveAs } from "file-saver";
// import timeZone from '../../store/modules/timeZone';

var CryptoJS = require("crypto-js");
export default {
  name: "Home",
  computed: {
    ...mapGetters("node", ["startNode", "getNodeTypeByName"]),
    ...mapGetters("credentials", ["getCredential"]),
    isRunning() {
      return !!this.currentExecutionId;
    },
    workflowId() {
      return this.$route.params.id;
    },
    isExecutionResult() {
      return !!this.$route.params?.executionId;
    },
  },
  mixins: [formDialog, eventBusMixin],
  components: {
    NodeList,
    EditDrawer,
    ExpressionDataSelector,
  },
  data: () => ({
    editorStartNode: null,
    editor: null,
    listShow: false,
    listPositionX: 0,
    listPositionY: 0,
    output_id: "",
    output_class: "",
    canvas_x: 0,
    canvas_y: 0,
    editDrawer: false,
    editedNode: null,
    workflowData: null,
    executionData: null,
    disableEdit: false,
    askBeforeExit: false,
    currentExecutionId: null,
    waitingForWebhook: false,
    eventBusListeners: [],
    fab: false,
    active: false,
    isSettingActivateStatus: false,
    saveSnackBar: false,
  }),
  mounted() {
    console.log("mounted");
    this.setup();
  },
  async beforeRouteLeave(to, from, next) {
    if (this.askBeforeExit) {
      let result = await this.$confirm({
        text:
          "You have unsaved changes, do you want to continue to exit the page ?",
        title: "Exit Page",
      });
      if (result.status) {
        next();
      }
    } else {
      next();
    }
  },
  created() {
    window.addEventListener("beforeunload", (event) => {
      if (this.askBeforeExit) {
        event.preventDefault();
        event.returnValue = "";
      }
    });
  },
  methods: {
    ...mapActions("node", ["loadNodes", "loadNodesDetailed"]),
    ...mapActions("credentials", ["loadCredentialTypes", "loadCredentials"]),
    ...mapMutations("editor", [
      "updateExpressionData",
      "updateContextExpressionData",
      "resetExpressionData",
    ]),
    removeOldData() {
      let nodes = this.editor.getNodes();
      for (const node of Object.values(nodes)) {
        node.data.executions = [];
      }
    },
    setup() {
      //this.$pushEvent('navigationDrawerStatus', false)
      NodeNamer.reset();
      if (this.$route.params.executionId) {
        this.disableEdit = true;
      }
      this.setupPushConnection();
      this.loadNodes().then(async () => {
        await this.onNodesLoaded();
        this.updateExpressionData(this.editor);
      });
      this.loadCredentialTypes();
      this.loadCredentials();
      this.prepareEditor();
    },
    async onRunButtonClicked() {
      this.removeOldData();
      await this.run();
    },
    async setActiveStatus() {
      this.isSettingActivateStatus = true;
      try {
        await Api.updateWorkflow(this.workflowId, { active: !this.active });
        this.active = !this.active;
      } catch (err) {
        this.$alert({
          text: err.response.data.message,
          type: "error",
          duration: 5,
        });
      }

      this.isSettingActivateStatus = false;
    },

    async removeStart() {
      this.editor.removeNodeId("node-" + this.editorStartNode.id);
    },

    async exportWorkFlow() {
      let { workflowData } = convertFlowData(this.editor.export());
      workflowData.nodes.forEach((node) => delete node["credentials"]);

      var encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(workflowData),
        "key"
      );

      const blob = new Blob([encryptedData], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "export.rbflow");
    },

    async pickFileField() {
      let { workflowData } = convertFlowData(this.editor.export());
      if (workflowData.nodes.length > 1) {
        this.$alert({
          text: "Import can only be operated on empty workflow",
          type: "error",
          duration: 3,
        });
      } else {
        this.$refs.fileInput.click();
      }
    },

    async onFilePicked(event) {
      try {
        const files = event.target.files;

        let workflow = await readFileAsText(files[0]);
        var bytes = CryptoJS.AES.decrypt(workflow, "key");

        let parsedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.removeStart();
        NodeNamer.reset();
        this.loadWorkflow(parsedData);
      } catch {
        this.$alert({
          text: "Invalid type of workflow file!",
          type: "error",
          duration: 3,
        });
      }
    },

    async run({ workflowExtras } = {}) {
      let workflowData = {
        ...convertFlowData(this.editor.export()),
        ...workflowExtras,
      };
      if (this.$route.params.id) {
        workflowData.workflowData.id = this.$route.params.id;
      }

      // console.log(workflowData)
      // const result =
      try {
        let result = await Api.runWorkflow(workflowData);
        this.currentExecutionId = result.executionId;
        this.waitingForWebhook = result.waitingForWebhook;
      } catch (err) {
        this.$alert({
          text: err.response.data.message,
          type: "error",
          duration: 3,
        });
      }

      // console.log(result)
    },
    async stopExecution() {
      await Api.stopCurrentExecution(this.currentExecutionId);
      this.currentExecutionId = null;
    },
    async deleteWebhook() {
      await Api.deleteTestWebhook(this.workflowId);
    },
    async saveFlow(event) {
      event.preventDefault();
      try {
        if (this.$route.params.id) {
          await this.updateFlow();
        } else {
          await this.createFlow();
        }
      } catch (e){
        await this.$alert({
          text: e.response.data.message,
          type: "error",
        });
      }
    },
    showSaveSnackBar() {
      this.saveSnackBar = true;
      setTimeout(() => {
        this.saveSnackBar = false;
      }, 1000);
    },
    async updateFlow() {
      let runData = convertFlowData(this.editor.export());
      delete runData.workflowData.name;
      delete runData.workflowData.active;
      delete runData.workflowData.settings;
      await Api.updateWorkflow(this.$route.params.id, runData.workflowData);
      this.showSaveSnackBar();
      this.askBeforeExit = false;
    },
    async createFlow() {
      let result = await this.$input({
        title: "Save Workflow",
        properties: [{ name: "name", displayName: "name", type: "string" }],
      });

      if (result.status) {
        let runData = convertFlowData(this.editor.export());
        runData.workflowData.name = result.data.name;
        let defaultTimezone = await srcApi.getTimeZone();

        runData.workflowData.settings.timezone = defaultTimezone.Value.timeZone;

        let { id } = await Api.createWorkflow(runData.workflowData);

        this.askBeforeExit = false;
        await this.$alert({
          text: "Workflow has been created successfully, rerouting...",
          type: "success",
        });

        window.location = "/workflows/" + id;
      }
    },
    setupPushConnection() {
      EventListener.connect();
      EventListener.setListener(
        EventListener.types.NODE_EXECUTE_BEFORE,
        (data) => {
          let node = this.editor.getFirstNodeFromName(data.nodeName);
          node.data.isExecuting = true;
        }
      );
      EventListener.setListener(
        EventListener.types.NODE_EXECUTE_AFTER,
        (data) => {
          console.log(data);
          let node = this.editor.getFirstNodeFromName(data.nodeName);
          node.data.isExecuting = false;

          node.data.executionResult = data.data;

          console.log(Array.isArray(node.data.executions));
          node.data.executions.push(data);
          this.updateExpressionData(this.editor);
        }
      );
      EventListener.setListener(EventListener.types.EXECUTION_STARTED, () => {
        this.resetExpressionData();
      });
      EventListener.setListener(
        EventListener.types.EXECUTION_FINISHED,
        (data) => {
          this.currentExecutionId = null;
          let contextData = data.data.data?.executionData?.contextData;
          if (contextData) {
            this.updateContextExpressionData(contextData);
          }
          let error = data.data.data?.resultData?.error?.message;
          if (error) {
            this.$alert({
              text: error,
              type: "error",
            });
          }
        }
      );
      EventListener.setListener(
        EventListener.types.TEST_WEBHOOK_DELETED,
        () => {
          this.waitingForWebhook = false;
        }
      );
      EventListener.setListener(
        EventListener.types.TEST_WEBHOOK_RECEIVED,
        () => {
          this.waitingForWebhook = false;
        }
      );
    },
    async onNodesLoaded() {
      if (this.workflowId) {
        console.log("workflow id: ", this.workflowId);
        this.loadWorkflow(await Api.getWorkflow(this.workflowId));
      } else if (this.$route.params.executionId) {
        let executionData = await Api.getExecutionData(
          this.$route.params.executionId
        );
        await this.loadWorkflow(executionData.workflowData);
        this.assignExecutionResult(executionData.data.resultData.runData);
      } else {
        this.addStartNode();
      }
    },
    assignExecutionResult(runData) {
      window.editor = this.editor;
      for (const [nodeName, executions] of Object.entries(runData)) {
        let node = this.editor.getFirstNodeFromName(nodeName);
        node.data.executionResult = executions[executions.length - 1];
        node.data.executions = executions.map((e) => ({ data: e }));
      }
    },
    async loadWorkflow(workflowData) {
      this.active = workflowData.active;
      let idNodeMap = {};
      await this.loadNodesDetailed(workflowData.nodes.map((n) => n.type));
      for (const node of workflowData.nodes) {
        let addedNode = this.addNode({
          node: this.getNodeTypeByName(node.type),
          position: { x: node.position[0], y: node.position[1] },
          parameters: node.parameters,
          credentials: node.credentials,
          disabled: node.disabled,
          name: node.name,
          settings: {
            alwaysOutputData: node.alwaysOutputData,
            continueOnFail: node.continueOnFail,
            retryOnFail: node.retryOnFail,
            maxTries: node.maxTries,
            waitBetweenTries: node.waitBetweenTries,
            executeOnce: node.executeOnce,
          },
        });
        idNodeMap[addedNode.name] = addedNode;
      }

      //There is no asynchronous function without an await up in there but seems like
      //render of the nodes is not finished when connections started to be rendered
      //So I put a small wait in here to give some time for nodes to be rendered
      await waitInMilliSeconds(100);

      //The code below makes the connections after all the nodes are loaded
      //TODO: refactor
      for (const [name, connectionData] of Object.entries(
        workflowData.connections
      )) {
        for (const port of Object.values(connectionData)) {
          for (const [index, connections] of Object.entries(port)) {
            let outputPortClass = "output_" + (Number(index) + 1);
            for (const connection of connections) {
              let targetPortClass = "input_1";
              let parameters = [
                idNodeMap[name].id,
                idNodeMap[connection.node].id,
                outputPortClass,
                targetPortClass,
              ];
              console.log(parameters);
              this.editor.addConnection(...parameters);
            }
          }
        }
      }
    },
    getPortClass(type, portname, classType) {
      let index = type.outputs.findIndex((p) => p == portname) + 1;
      return classType + `_` + index;
    },
    addStartNode() {
      this.editorStartNode = this.addNode({
        node: this.startNode,
        position: {
          x: 150,
          y: 300,
        },
      });
    },
    exportFlow() {
      console.log(this.editor.export());
    },
    openNodeList(posX, posY) {
      this.listShow = false;
      this.listPositionX = posX;
      this.listPositionY = posY;
      this.listShow = true;
    },
    closeNodeList(removeConnection) {
      this.listShow = false;
      if (removeConnection) {
        this.editor.removeActiveConnection();
      }
    },
    nodeDataEdited({ data, id, credentials, settings }) {
      // console.log('saved data', data)
      this.askBeforeExit = true;
      const node = this.editor.getActualNodeFromId(id);
      node.data.data = data;
      node.data.credentials = credentials;
      node.data.settings = settings;
    },
    // eslint-disable-next-line no-unused-vars
    async nodeSelected({ node, position }) {
      this.askBeforeExit = true;
      this.closeNodeList(true);
      await this.loadNodesDetailed([node.name]);
      let nodeType = this.getNodeTypeByName(node.name);
      let { defaultData } = convert(nodeType.properties, {
        isWebhookNode: nodeType.name === "n8n-nodes-base.webhook",
      });
      const createdNode = this.addNode({
        node: nodeType,
        position: {
          x: this.canvas_x,
          y: this.canvas_y,
        },
        parameters: defaultData,
      });
      if (node.inputs.length > 0 && this.output_id && this.output_class) {
        const outputId = this.output_id.slice(5);
        const parameters = [
          Number(outputId),
          createdNode.id,
          this.output_class,
          "input_1",
        ];

        this.editor.addConnection(...parameters);
      }
      this.$pushEvent("onNodeEdit", { nodeId: createdNode.id });
    },
    addNode({ node, position, parameters = {}, name, ...extra }) {
      const _name = NodeNamer.getName(name ?? node.defaults.name);
      const createdNode = this.editor.getActualNodeFromId(
        this.editor.addNode(
          _name,
          node.inputs.length,
          node.outputs.length,
          position.x,
          position.y,
          "",
          {
            node,
            isExecuting: false,
            name: _name,
            executions: [],
            data: parameters,
            ...extra,
          },
          "test",
          "vue"
        )
      );
      this.updateExpressionData(this.editor);
      return createdNode;
    },
    async paste() {
      let paste = await navigator.clipboard.readText();
      switch (paste.split(":")[0]) {
        case "node-data":
          this.pasteNode(JSON.parse(paste.slice("node-data:".length)));
          break;
      }
    },
    pasteNode(nodeData) {
      this.addNode({
        node: nodeData.node,
        position: {
          x:
            this.editor.pos_x -
            this.editor.canvas_x -
            this.editor.container.getBoundingClientRect().left,
          y:
            this.editor.pos_y -
            this.editor.canvas_y -
            this.editor.container.getBoundingClientRect().top,
        },
        parameters: nodeData.data,
        name: nodeData.name,
        settings: nodeData.settings,
        credentials: nodeData.credentials,
      });
    },
    updateNodeName({ name, id }) {
      this.askBeforeExit = true;
      const node = this.editor.getActualNodeFromId(id);
      node.name = name;
      node.data.name = name;
      this.updateExpressionData(this.editor);
    },
    getCurrentRunData() {
      let nodes = this.editor.getNodes();
      let runData = {};
      let found = false;
      for (const node of Object.values(nodes)) {
        if (node.data.executions.length > 0) {
          found = true;
          runData[node.name] = node.data.executions.map((e) => e.data);
        }
      }
      return found ? runData : null;
    },
    prepareEditor() {
      var id = document.getElementById("home");
      this.editor = new Drawflow(id, Vue);
      this.editor.editor_enable_delete_menu = false;
      if (this.disableEdit) {
        this.editor.editor_mode = "fixed";
      }
      this.editor.start();
      this.editor.on("nodeMoved", () => {
        this.askBeforeExit = true;
      });
      this.editor.on("nodeRemoved", () => {
        this.askBeforeExit = true;
      });
      this.editor.on(
        "connectionReleased",
        //eslint-disable-next-line
        ({ pos_x, pos_y, canvas_x, canvas_y, output_id, output_class }) => {
          this.openNodeList(pos_x, pos_y);
          this.output_id = output_id;
          this.output_class = output_class;
          this.canvas_x = canvas_x;
          this.canvas_y = canvas_y;
        }
      );
      this.editor.on("canvasRightClick", ({ event, canvasX, canvasY }) => {
        this.openNodeList(event.clientX, event.clientY);
        this.canvas_x = event.offsetX - canvasX;
        this.canvas_y = event.offsetY - canvasY;
        this.output_id = null;
        this.output_class = null;
      });
      // this.editor.on('nodeDoubleClick', id => {
      //   this.editedNode = JSON.parse(
      //     JSON.stringify(this.editor.getActualNodeFromId(id))
      //   )

      //   this.editDrawer = true
      // })
      this.$subscribe("onNodeEdit", ({ nodeId }) => {
        this.editedNode = deepCopy(this.editor.getActualNodeFromId(nodeId));
        this.editDrawer = true;
      });

      this.$subscribe("onNodeDisabled", ({ nodeId }) => {
        this.editor.getActualNodeFromId(nodeId).data.disabled = true;
        this.editor.addNodeClass("node-" + nodeId, "disabled");
      });

      this.$subscribe("onNodeEnabled", ({ nodeId }) => {
        this.editor.getActualNodeFromId(nodeId).data.disabled = false;
        this.editor.removeNodeClass("node-" + nodeId, "disabled");
      });

      this.$subscribe("onNodeDeleted", ({ nodeId }) => {
        this.editor.removeNodeId("node-" + nodeId);
      });

      this.$subscribe("onNodeRunned", ({ nodeName }) => {
        let workflowExtras = {
          startNodes: [nodeName],
          destinationNode: nodeName,
        };
        let runData = this.getCurrentRunData();
        if (runData) {
          workflowExtras.runData = runData;
        }
        this.run({ workflowExtras, removeOldData: false });
      });

      this.editor.registerNode("test", Node, {}, {});
    },
  },
};
</script>
