<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="shadow-xl pa-5" :loading="timeZoneIsLoading">
        <v-card-text>
          <v-toolbar-title>Time Zone</v-toolbar-title>
          <v-divider class="my-5"></v-divider>
          <v-alert v-show="!!timeZoneError" text prominent type="error" icon="mdi-alert-circle">{{ timeZoneError }}</v-alert>
          <v-alert v-show="!!timeZoneSuccess" text prominent type="success" icon="mdi-cloud-check">Time zone has been updated</v-alert>
          <v-form>
            <v-autocomplete outlined v-model="value" :items="timeZones" item-text="fullName" item-value="Value" label="Time Zone"></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="saveTimeZone(value)" :disabled="timeZoneIsLoading" depressed large block>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FETCH_TIME_ZONES, FETCH_TIME_ZONE, SAVE_TIME_ZONE } from "../../store/modules/timeZone";

export default {
  data: () => ({
    value: "",
  }),
  computed: {
    ...mapState("timeZone", {
      selectedTimeZone: (state) => state.timeZone,
      timeZones: (state) =>
        state.timeZones.map((i) => ({
          ...i,
          fullName: `${i.Name} - ${i.Value}`,
        })),
      timeZoneIsLoading: (state) => state.isLoading,
      timeZoneSuccess: (state) => state.success,
      timeZoneError: (state) => state.error,
    }),
  },
  watch: {
    selectedTimeZone(newVal) {
      this.value = newVal?.Value.timeZone;
    },
  },
  created() {
    this.fetchTimeZones();
    this.fetchTimeZone();
  },
  methods: {
    ...mapActions("timeZone", {
      fetchTimeZones: FETCH_TIME_ZONES,
      fetchTimeZone: FETCH_TIME_ZONE,
      saveTimeZone: SAVE_TIME_ZONE,
    }),
  },
};
</script>
