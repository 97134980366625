import Api from "../../api";
export const FETCH_ASSETS = "FETCH_ASSETS";
export const FILTER_ASSETS = "FILTER_ASSETS";
export const TOGGLE_CREATE_ASSET_DIALOG = "TOGGLE_CREATE_ASSET_DIALOG";
export const CREATE_ASSET = "CREATE_ASSET";
export const TOGGLE_UPDATE_ASSET_DIALOG = "TOGGLE_UPDATE_ASSET_DIALOG";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const TOGGLE_DELETE_ASSET_DIALOG = "TOGGLE_DELETE_ASSET_DIALOG";
export const DELETE_ASSET = "DELETE_ASSET";
export const FETCH_TYPES = "FETCH_TYPES";
export const FETCH_LABELS = "FETCH_LABELS";
export const TOGGLE_LABEL_DIALOG = "TOGGLE_LABEL_DIALOG";
export const CREATE_LABEL = "CREATE_LABEL";
export const UPDATE_LABEL = "UPDATE_LABEL";
export const DELETE_LABEL = "DELETE_LABEL";
export const CHANGE_DIALOG = "CHANGE_DIALOG";

const state = {
  assets: [],
  pagedAssets: [],
  totalCount: 0,
  assetIsLoading: false,
  createAsset: {
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  updateAsset: {
    item: {},
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  deleteAsset: {
    id: 0,
    dialog: false,
    isLoading: false,
    success: false,
    error: "",
  },
  types: [],
  labels: [],
  labelIsLoading: false,
  labelDialog: false,
  createLabel: {
    isLoading: false,
    success: false,
    error: "",
  },
  updateLabel: {
    isLoading: false,
    success: false,
    error: "",
  },
  deleteLabel: {
    isLoading: false,
    success: false,
    error: "",
  },
  dialog: "",
};

const actions = {
  [FETCH_ASSETS]: async ({ commit }) => {
    commit("setAssetLoading", true);

    let list = [];

    try {
      list = await Api.getAssets();
      commit("setAssets", list);
    } catch (err) {
      commit("setAssets", []);
    } finally {
      commit("setAssetLoading", false);
    }

    return list;
  },
  [FILTER_ASSETS]: async ({ commit }, options) => {
    commit("setAssetLoading", true);

    try {
      let result = await Api.filterAssets(options);
      commit("setPagedAssets", result.rows);
      commit("setTotalCount", result.count);
    } catch (err) {
      commit("setPagedAssets", []);
      commit("setTotalCount", 0);
    } finally {
      commit("setAssetLoading", false);
    }
  },
  [TOGGLE_CREATE_ASSET_DIALOG]: ({ commit }) => {
    commit("setCreateAssetDialog");
  },
  [CREATE_ASSET]: async ({ commit, dispatch }, asset) => {
    commit("setCreateAssetLoading", true);

    try {
      await Api.createAsset(asset);
      commit("setCreateAssetSuccess");
    } catch (error) {
      commit("setCreateAssetError", error.response.data.reason);
    } finally {
      commit("setCreateAssetLoading", false);
      dispatch(FILTER_ASSETS, { itemsPerPage: 10, page: 0 });
    }
  },
  [TOGGLE_UPDATE_ASSET_DIALOG]: ({ commit }, item = {}) => {
    commit("setUpdateAssetDialog", item);
  },
  [UPDATE_ASSET]: async ({ commit, dispatch }, { assetId, asset }) => {
    commit("setUpdateAssetLoading", true);

    try {
      await Api.updateAsset(assetId, asset);
      commit("setUpdateAssetSuccess");
      // commit('setUpdateAssetDialogById', assetId)
    } catch (error) {
      commit("setUpdateAssetError", error.response.data.reason);
    } finally {
      commit("setUpdateAssetLoading", false);
      dispatch(FILTER_ASSETS, { itemsPerPage: 10, page: 0 });
    }
  },
  [TOGGLE_DELETE_ASSET_DIALOG]: ({ commit }, id = 0) => {
    commit("setDeleteAssetDialog", id);
  },
  [DELETE_ASSET]: async ({ commit, dispatch }, assetId) => {
    commit("setDeleteAssetLoading", true);

    try {
      await Api.deleteAsset(assetId);
      commit("setDeleteAssetSuccess");
    } catch (error) {
      commit("setDeleteAssetError", error.response.data.reason);
    } finally {
      commit("setDeleteAssetLoading", false);
      dispatch(FILTER_ASSETS, { itemsPerPage: 10, page: 0 });
    }
  },
  [TOGGLE_LABEL_DIALOG]: ({ commit }) => {
    commit("setLabelDialog");
  },
  [FETCH_TYPES]: async ({ commit }) => {
    try {
      let types = await Api.getAssetTypes();
      commit("setTypes", types);
    } catch (error) {
      commit("setTypes", []);
    }
  },
  [FETCH_LABELS]: async ({ commit }) => {
    commit("setLabelLoading", true);

    try {
      let labels = await Api.getLabels();
      commit("setLabels", labels);
    } catch (error) {
      commit("setLabels", []);
    } finally {
      commit("setLabelLoading", false);
    }
  },
  [CREATE_LABEL]: async ({ commit, dispatch }, label) => {
    commit("clearLabel");
    commit("setCreateLabelLoading", true);

    try {
      await Api.createLabel(label);
      commit("setCreateLabelSuccess");
    } catch (error) {
      commit("setCreateLabelError", error.response.data.reason);
    } finally {
      commit("setCreateLabelLoading", false);
      dispatch(FETCH_LABELS);
    }
  },
  [UPDATE_LABEL]: async ({ commit, dispatch }, { labelId, label }) => {
    commit("clearLabel");
    commit("setUpdateLabelLoading", true);

    try {
      await Api.updateLabel(labelId, label);
      commit("setUpdateLabelSuccess");
    } catch (error) {
      commit("setUpdateLabelError", error.response.data.reason);
    } finally {
      commit("setUpdateLabelLoading", false);
      dispatch(FETCH_LABELS);
    }
  },
  [DELETE_LABEL]: async ({ commit, dispatch }, labelId) => {
    commit("clearLabel");
    commit("setDeleteLabelLoading", true);

    try {
      await Api.deleteLabel(labelId);
      commit("setDeleteLabelSuccess");
    } catch (error) {
      commit("setDeleteLabelError", error.response.data.reason);
    } finally {
      commit("setDeleteLabelLoading", false);
      dispatch(FETCH_LABELS);
    }
  },
  [CHANGE_DIALOG]: async ({ commit }, dialog) => {
    commit("setDialog", dialog);
  },
};

const mutations = {
  setAssetLoading: (state, isLoading) => (state.assetIsLoading = isLoading),
  setAssets: (state, assets) => (state.assets = assets),

  setPagedAssets: (state, assets) => (state.pagedAssets = assets),
  setTotalCount: (state, count) => (state.totalCount = count),

  setCreateAssetDialog: (state) => {
    state.createAsset.dialog = !state.createAsset.dialog;
    state.createAsset.isLoading = false;
    state.createAsset.success = false;
    state.createAsset.error = "";
  },
  setCreateAssetLoading: (state, isLoading) => (state.createAsset.isLoading = isLoading),
  setCreateAssetSuccess: (state) => (state.createAsset.success = true),
  setCreateAssetError: (state, error) => (state.createAsset.error = error),

  setUpdateAssetDialog: (state, item) => {
    state.updateAsset.item = {
      ...item,
      Labels:
        item &&
        item.Labels &&
        item.Labels.map((label) => {
          return label.Id;
        }),
    };

    state.updateAsset.dialog = !state.updateAsset.dialog;
    state.updateAsset.isLoading = false;
    state.updateAsset.success = false;
    state.updateAsset.error = "";
  },
  setUpdateAssetDialogById: (state, id) => {
    state.updateAsset.item = state.assets.find((asset) => {
      return asset.Id === id;
    });
  },
  setUpdateAssetLoading: (state, isLoading) => (state.updateAsset.isLoading = isLoading),
  setUpdateAssetSuccess: (state) => (state.updateAsset.success = true),
  setUpdateAssetError: (state, error) => (state.updateAsset.error = error),

  setDeleteAssetDialog: (state, id) => {
    state.deleteAsset.id = id;
    state.deleteAsset.dialog = !state.deleteAsset.dialog;
    state.deleteAsset.isLoading = false;
    state.deleteAsset.success = false;
    state.deleteAsset.error = "";
  },
  setDeleteAssetLoading: (state, isLoading) => (state.deleteAsset.isLoading = isLoading),
  setDeleteAssetSuccess: (state) => (state.deleteAsset.success = true),
  setDeleteAssetError: (state, error) => (state.deleteAsset.error = error),

  setTypes: (state, types) => (state.types = types),

  setLabelLoading: (state, isLoading) => (state.labelIsLoading = isLoading),
  setLabels: (state, labels) => (state.labels = labels),
  setLabelDialog: (state) => (state.labelDialog = !state.labelDialog),

  setCreateLabelLoading: (state, isLoading) => (state.createLabel.isLoading = isLoading),
  setCreateLabelSuccess: (state) => (state.createLabel.success = true),
  setCreateLabelError: (state, error) => (state.createLabel.error = error),

  setUpdateLabelLoading: (state, isLoading) => (state.updateLabel.isLoading = isLoading),
  setUpdateLabelSuccess: (state) => (state.updateLabel.success = true),
  setUpdateLabelError: (state, error) => (state.updateLabel.error = error),

  setDeleteLabelLoading: (state, isLoading) => (state.deleteLabel.isLoading = isLoading),
  setDeleteLabelSuccess: (state) => (state.deleteLabel.success = true),
  setDeleteLabelError: (state, error) => (state.deleteLabel.error = error),

  setDialog: (state, dialog) => (state.dialog = dialog),

  clearLabel: (state) => {
    state.createLabel.success = false;
    state.createLabel.error = "";
    state.updateLabel.success = false;
    state.updateLabel.error = "";
    state.deleteLabel.success = false;
    state.deleteLabel.error = "";
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
