<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <v-card class="shadow-xl pa-5" :loading="isLoading">
        <v-card-text>
          <v-toolbar-title>SMTP Information</v-toolbar-title>
          <v-divider class="my-5"></v-divider>
          <v-alert v-show="!!error" text prominent type="error" icon="mdi-alert-circle">{{ error }}</v-alert>
          <v-alert v-show="!!success" text prominent type="success" icon="mdi-cloud-check">Smtp information has been updated.</v-alert>
          <v-form ref="form" v-on:submit.prevent="save">
            <v-text-field label="Host" v-model="smtpInfo.Host" type="text" required outlined :rules="hostRules" maxlength="50" counter />
            <v-text-field label="Port" v-model="smtpInfo.Port" type="number" required outlined :rules="portRules" />
            <v-text-field
              label="Username"
              v-model="smtpInfo.User"
              type="text"
              
              outlined

              maxlength="100"
              counter
              autocomplete="off"
              readonly
              onfocus="this.removeAttribute('readonly')"
            />
            <v-text-field
              label="Password"
              v-model="smtpInfo.Password"
              type="password"
              
              outlined

              maxlength="100"
              counter
              autocomplete="off"
              readonly
              onfocus="this.removeAttribute('readonly')"
            />
            <v-text-field
              label="From"
              v-model="smtpInfo.From"
              :placeholder="fromPlaceholder"
              type="text"
              required
              outlined
              :rules="fromRules"
              maxlength="200"
              counter
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="save" depressed large block>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { UPDATE_SMTP, FETCH_SMTP } from "../../store/modules/smtpInformation";

export default {
  data: () => ({
    smtpInfo: {
      Host: "",
      Port: "",
      User: "",
      Password: "",
      From: "",
    },
    fromPlaceholder: '"Robbot" <smtp@robbot.com.tr>',
    hostRules: [(v) => !!v || "Host is required"],
    portRules: [(v) => !!v || "Port is required"],
    usernameRules: [(v) => !!v || "Username is required"],
    passwordRules: [(v) => !!v || "Password is required"],
    fromRules: [(v) => !!v || "From is required"],
  }),
  computed: {
    ...mapState("smtp", {
      smtp: (state) => state.smtp,
      isLoading: (state) => state.isLoading,
      success: (state) => state.success,
      error: (state) => state.error,
    }),
  },
  watch: {
    smtp(value) {
      if (value) {
        Object.assign(this.smtpInfo, value);
      }
    },
  },
  created() {
    this.fetchSmtp();
  },
  methods: {
    ...mapActions("smtp", {
      updateSmtp: UPDATE_SMTP,
      fetchSmtp: FETCH_SMTP,
    }),
    save() {
      if (!this.$refs.form.validate()) return;

      this.updateSmtp(this.smtpInfo);
    },
  },
};
</script>
