<template>
  <div>
    <v-data-table :loading="loading" :headers="headers" :items="items" :options.sync="options"
      :server-items-length="totalExecutions" :page.sync="currentPage" class="shadow-xl mx-5 my-5">

      <template v-slot:top>
        <v-toolbar flat color="white" class="pt-3">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>Executions</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="px-5">
          <v-col cols="12" sm="4" md="4" lg="4">
            <v-select class="mr-5" label="Workflow" :items="workflows" v-model="filterWorkflow" />
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4">
            <v-select class="mr-5" label="Status" :items="[
              { text: 'all', value: null },
              { text: 'success', value: true },
              { text: 'failure', value: false },
              { text: 'running', value: 'running' },
            ]" v-model="filterFinished" />
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <vue-json-to-csv :json-data="items" :csv-title="'executions'">
                  <v-btn color="primary" block>
                    <v-icon left dark>
                      mdi-download
                    </v-icon>
                    CSV
                  </v-btn>
                </vue-json-to-csv>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <download-excel :data="items" :name="'executions'">
                  <v-btn color="primary" block>
                    <v-icon left dark>
                      mdi-download
                    </v-icon>
                    Excel
                  </v-btn>
                </download-excel>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.runningTime="{ item }">
        {{ (new Date(item.stoppedAt) - new Date(item.startedAt)) / 1000 }}
        sec.
      </template>
      <template v-slot:item.finished="{ item }">
        <v-chip color="yellow" v-if="item.finished === null">
          Running
        </v-chip>
        <v-chip color="success" v-if="item.finished === true">Success</v-chip>
        <v-chip color="error" v-if="item.finished === false">Failed</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn elevation="0" class="mr-2" :to="`executions/${item.id}`">
          <v-icon>visibility</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Api from "../api";
import { mapState, mapActions } from "vuex";
import { timeAwareFormat } from "../../time";
import VueJsonToCsv from "vue-json-to-csv"
import JsonExcel from "vue-json-excel";

export default {
  computed: {
    ...mapState("workflows", {
      workflows: (state) => [{ text: "all", value: null }, ...state.workflows.map((i) => ({ text: i.name, value: i.id }))],
    }),
    filter() {
      let workflowId = this.filterWorkflow === null ? undefined : this.filterWorkflow;
      let finished = this.filterFinished === null ? undefined : this.filterFinished;
      if (this.filterFinished === "running") {
        return { workflowId };
      } else {
        return { workflowId, finished };
      }
    },
  },
  components: {
    "vue-json-to-csv": VueJsonToCsv,
    "downloadExcel": JsonExcel,
  },
  data: () => ({
    currentPage: 1,
    totalExecutions: 0,
    loading: false,
    headers: [
      { text: "Name", value: "workflowName", sortable: false },
      { text: "Mode", value: "mode", sortable: false },
      { text: "Running Time (Seconds)", value: "runningTime", sortable: false },
      { text: "Started At", value: "startedDate", sortable: false },
      { text: "Status", value: "finished", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    activeLoading: {},
    options: {},
    lastId: null,
    items: [],
    filterFinished: undefined,
    filterWorkflow: undefined
  }),
  watch: {
    filterFinished() {
      this.loadData();
    },
    filterWorkflow() {
      this.loadData();
    },
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      const { itemsPerPage } = this.options;
      let fetchFunctionName = this.filterFinished === "running" ? "getCurrentExecutions" : "getExecutions";
      let result = await Api[fetchFunctionName]({
        limit: itemsPerPage,
        lastId: this.lastId,
        filter: this.filter,
        page: this.currentPage
      });
      this.items = result.results?.map((item) => {
        return { ...item, startedDate: timeAwareFormat(item.startedAt), stoppedDate: timeAwareFormat(item.stoppedAt) };
      });
      this.totalExecutions = result.count;
      this.loading = false;
    },
    ...mapActions("workflows", ["loadWorkflows"]),
  },
  mounted() {
    this.loadWorkflows();
    this.loadData();
  },
};
</script>
