<template>
  <div>
    <v-skeleton-loader v-if="isLoading" ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
    <v-data-table
      :headers="headers"
      :items="scripts"
      class="shadow-xl mx-5 my-5"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      v-else
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-none d-sm-flex">
            <v-toolbar-title>
              Scripts
              <v-progress-circular v-show="isRefreshing" indeterminate color="primary"></v-progress-circular>
            </v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <router-link to="/addscript">
            <v-btn depressed color="primary" v-if="hasAuth('New Script')"> <v-icon left>add</v-icon>Add Script </v-btn>
          </router-link>
          <edit-script-dialog />
          <delete-script-dialog />
        </v-toolbar>
      </template>
      <template v-slot:item.Description="{ item }">
        <v-tooltip bottom v-if="item.Description">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ substrDescriptionField(item.Description) }}</span>
          </template>
          <span>{{ item.Description }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom v-if="item.DataEdit">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="openEditScriptDialog(item)" v-on="on">
              <v-icon size="18">edit</v-icon>
            </v-btn>
          </template>
          <span>Edit Script</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.DataDelete">
          <template v-slot:activator="{ on }">
            <v-btn depressed class="mx-1" @click="openDeleteDialog(item)" v-on="on">
              <v-icon size="18">delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Script</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.DataDownload">
          <template v-slot:activator="{ on }">
            <v-btn v-if="item.FileURL" depressed @click="download(item.FileURL)" v-on="on">
              <v-icon size="18">get_app</v-icon>
            </v-btn>
          </template>
          <span>Download File</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data></template>

      <template v-slot:item.status="{ item }">
        <v-chip :color="item.status" dark>{{ item.status }}</v-chip>
      </template>
    </v-data-table>
    <AddScriptDialog v-model="showBasicScriptDialog" :script="editedItem" />
  </div>
</template>

<script>
import EditScriptDialog from "./EditScriptDialog";
import DeleteScriptDialog from "./DeleteScriptDialog";
import AddScriptDialog from "./AddScriptDialog";

import { mapActions, mapState } from "vuex";
import { FETCH_SCRIPT_WITH_PAGE, OPEN_EDIT_SCRIPT_DIALOG, OPEN_DELETE_SCRIPT_DIALOG } from "../store/modules/script";
import Api from "../api";
import { timeAwareFormat } from "../time";
import { hasActionAuth } from "../authorization";

export default {
  components: {
    "edit-script-dialog": EditScriptDialog,
    "delete-script-dialog": DeleteScriptDialog,
    AddScriptDialog,
  },
  data: () => ({
    showBasicScriptDialog: false,
    options: { itemsPerPage: 10, page: 1 },
    plugin: null,
    editedItem: {},
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
    headers: [
      {
        text: "Script Name",
        align: "left",
        sortable: true,
        value: "Name",
      },
      {
        text: "Description",
        value: "Description",
      },
      {
        text: "Script Version",
        value: "ScriptVersion",
      },
      {
        text: "File Version",
        value: "FileVersion",
      },
      {
        text: "Created Time",
        value: "createdAt",
      },
      {
        text: "Created",
        value: "Created.Name",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,

    defaultItem: {
      scriptName: "",
      createdtime: "",
      description: "",
      commandLine: "",
      files: "",
    },
  }),

  computed: {
    ...mapState("script", {
      scripts: (state) =>
        state.pageScripts.map((i) => ({
          ...i,
          createdAt: timeAwareFormat(i.createdAt),
        })),
      totalCount: (state) => state.totalCount,
      isRefreshing: (state) => state.isRefreshing,
      isLoading: (state) => state.isLoading,
    }),
  },

  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal.page != oldVal.page || newVal.itemsPerPage != oldVal.itemsPerPage) {
          this.fetchScripts({ options: newVal, isRefreshing: false });
        }
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.fetchScripts({ options: this.options, isRefreshing: false });
  },

  methods: {
    ...mapActions("script", {
      fetchScripts: FETCH_SCRIPT_WITH_PAGE,
      openEditDialog: OPEN_EDIT_SCRIPT_DIALOG,
      openDeleteDialog: OPEN_DELETE_SCRIPT_DIALOG,
    }),
    openEditScriptDialog(item) {
      if (item.IsBasic) {
        this.editedItem = item;
        this.showBasicScriptDialog = true;
      } else {
        this.openEditDialog(item);
      }
    },
    editItem(item) {
      this.editedIndex = this.scripts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.scripts.indexOf(item);
      confirm("Are you sure you want to delete this Script?") && this.scripts.splice(index, 1);
    },

    download(url) {
      Api.downloadFile(url);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.scripts[this.editedIndex], this.editedItem);
      } else {
        this.scripts.push(this.editedItem);
      }
      this.close();
    },
    hasAuth(action) {
      return hasActionAuth("Scripts", action);
    },
    substrDescriptionField(description) {
      if (description && description.length <= 30) return description;

      return `${description.substring(0, 30)}...`;
    },
  },
};
</script>

<style>
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.Active {
  background-color: #27ae60 !important;
}

.Deactive {
  background-color: #c0392b !important;
}
</style>
